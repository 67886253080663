@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$half: percentage(1/2);
$third: percentage(1/3);
$quarter: percentage(1/4);
$fifth: percentage(1/5);

.admin-fieldset {
  @include pie-clearfix();
  
//  position: relative;
  display: block;

  
}