// finder

.nav-menu {
	-ms-user-select: none; /* IE 10+ */
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;

	position: relative;
	width: 100%;

	font-size: 14px;
	line-height: 24px;

}

.nav-menu__treeitem,
.nav-menu__item {
  position: relative;

  &[data-count]:after {
    position: absolute;
    content: attr(data-count);
    top: 0;
    right: 0;
    margin: 2px;
    
    font-size: 12px;
    opacity: 0.5;
    
  }
  
}

.nav-menu__item,
.nav-menu__section {
  position: relative;

	+ .nav-menu__section {
    	border-top: 1px solid rgba(black, 0.25);
        margin-top: 12px;
        padding-top: 12px;
	}
  
}

.nav-menu__section {

	+ .nav-menu__item {
    	border-top: 1px solid rgba(black, 0.25);
        margin-top: 12px;
        padding-top: 12px;
	}
  
}

.nav-menu__item + .nav-menu__item {
    margin-top: 6px;
}