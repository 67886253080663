@import "../../../resources/sass/mixins/all";
@import "../../../resources/sass/settings/all";

.admin-preview__field.field--media {
    
}

.admin-preview__field.field--media  {
    
    + .field--media {
        margin-top: 1em;
    }
    
    .field__media {
        position: relative;
        background-color: #666;
        padding-bottom: 100%;
        padding-bottom: percentage(9/16);
    
        &[data-format="16:9"] {
          padding-bottom: percentage(9/16);
        }
    }

  img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
  }

  .media {
    

  }
  
  .video {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .audio {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
  
  .media__preview {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: auto;
    transform: translateY(-50%);
    text-align: center;
  }

  .media__title {
    font-size: 1em;
    font-weight: bold;
    display: block;
    margin: 0;
  }

  .media__description {
    font-size: 1em;
  }
  
  .media__caption {
    margin-top: 0.5em;
  }
  
  .media__credit {
    font-size: smaller;
    text-transform: capitalize;
    
    &:before {
      content: "(" attr(data-prefix) ": "
    }
    
    &:after {
      content: ")"
    }
    
  }  

  
  
}