.nav-search {
    position: relative;

    input[type="search"] {
        background-color: transparent;
        float: left;
        width: 100%;

        padding: 20px;
        padding-left: 64px;

        font-family: inherit;
        font-size: 18px;
        line-height: 24px;
        color: inherit;    
        
        border: none;
        outline: none;
        
        &:focus + .input__icon {
            opacity: 1;
        }
        
    }
    
    .input__icon {
        pointer-events: none;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        font-size: 24px;
        line-height: 24px;
        margin: 20px;

        width: 1em;
        height: 1em;

        color: inherit;        
        
        opacity: 0.5;
        
    }

}
