@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget {
    @include pie-clearfix();
    
    position: relative;
    text-decoration: none;
  
 	width: 100%;

 	justify-self: stretch;
 	align-self: stretch;
 	
 	font-family: $font-sans;
    font-size: 14px;
 	line-height: 20px;
 	
 	color: inherit;
 	
 	// border, etc
 	
 	background-color: white;
 	color: black;

    border-radius: 1px;
 	
 	@include card-depth(1)


    // SETTINGS

	.nav-settings {
    	position: absolute;
    	top: 0;
    	right: 0;
    	margin: 6px;
    	
    	opacity: 0.25;
	}
	
	.nav-action {
    	position: absolute;
    	top: 0;
    	right: 0;
    	
    	margin: 16px 12px;
    	
	}
	
	&:hover {
    	.nav-settings {
            opacity: 1;
    	}
	}

    // ADD
	
    .admin-button--add {
        position: absolute;
        padding: 12px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    // EXPAND

    .admin-button--expand {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 12px;
        
        &[aria-expanded="true"] {
            
            .admin-button__icon {
                transform: rotate(180deg);
            }

        }
        
    }
    
    &[aria-expanded="true"] {
        
        .admin-widget__header {
        
        }
        
    }

}

.admin-widget__grid {
    
}

.admin-widget__header {
    @include pie-clearfix();
}

.admin-widget__body {
    @include pie-clearfix();
}

.admin-widget__footer {
    @include pie-clearfix();
}

.admin-widget__body {
}

.admin-widget__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    margin: 0;
}
    
.admin-widget__description {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

