@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// mixins

@import "./Module__Small";
@import "./Module__Inline";

// expanded

.admin-module[data-layout="edit"] {
    @include module-size-small();
    @include module-inline();
    
    + .admin-module {
        margin-top: 6px
    }
    
    &.sortable-helper {
        @include card-depth(2);
        z-index: 3000;
    }

    .nav-sortable {
        position: absolute;
        top: 0;
        left: 0;
        
        + .admin-module__link {
            margin-left: 44px - 12px;
        }
        
    }
    
    .admin-module__grid {
        position: relative;
    }
    
    .admin-module__header {
        @include pie-clearfix()

        .admin-module__color,
        .admin-module__icon {
            float: left;
            margin-right: 6px;
        }
        
        .nav-tabs {
            float: left;
            margin-top: -8px;
        }
      
        .nav-action {
            float: right;
        }

    }
    
    .admin-module__footer {
        padding: 12px;
    }
    
    .admin-module__body {
        @include pie-clearfix()
        position: relative;
        padding: 12px;
        
        .admin-module__content {
            padding: 0;
        }

        .admin-module__media {
            position: absolute;
            top: 0;
            left: 0;
            
            width: 200px;
            height: 200px;
            
            background-color: $grey;
            
            margin: 12px;
            
            /*
            
            width: percentage(1/3);
            position: relative;
            padding-bottom: percentage(1/1/3);
          
            */
          
            + .admin-module__content {
                float: right;
                padding-left: 200px + 12px;
                width: 100%;
                min-height: 200px;
            }
          
        }

    }
    
    .admin-module__content {
        position: relative;

        .edit__cropdata,
        .edit__focalpoint {
            left: 200px + 12px;
        }

    }
    
}