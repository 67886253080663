@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module__email {
    display: inline;
  	max-width: 100%;

    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

  	font-weight: normal;
  	line-height: inherit;
  	
  	vertical-align: top;
  	opacity: 0.75;
  	
  	&:before {
        content: "<"
  	}

  	&:after {
        content: ">"
  	}
  	
}