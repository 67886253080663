@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$max-owners: 7;

// owners

.nav-apps__by-owner {
    @include pie-clearfix();
    position: relative;

    border-top: 1px solid #ccc;

    font-size: 16px;
    
    .admin-widget__search {
        @include pie-clearfix();

        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
        bottom: auto;

        margin: 11px;
        
        + .admin-widget__results {
            padding-top: 60px;
        }

    }

    .admin-widget__results {
        @include pie-clearfix();

        display: block;
        overflow-y: scroll;
        max-height: 60px * $max-owners;
    }

    .admin-widget__empty {
        margin: 1em
    }

    
}

// owner

.nav-apps__owner {
    @include pie-clearfix();

    display: block;
    text-align: center;

    font-size: 1em;
    line-height: 1em;    

    border-top: 1px solid $light;
    
    button {
        display: inline-block;
    }

	.admin-apps {
    	text-align: left;
    	padding: 20px 25px;
    	margin-top: -20px;
	}
    
}

.nav-apps__owner-header {
    display: block;
    position: relative;
    padding: 1em;
    padding-top: 20px;

    h2 {
        display: inline-block;
    }
    
    .admin-button--switch {
        display: inline;
        font-size: 0.75em;
        margin: 0 0.5em;
    }
    
} 