// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// settings

$week_w: 24px;
$week_h: 48px * 3;

// calendar table

.admin-calendar__table {
	display: block;
	width: 100%;
	
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	font-size: 14px;
	line-height: 24px;

	thead,
	tbody {
		@include pie-clearfix();
		display: block;
	    width: 100%;
	}
	
    tr {
		@include pie-clearfix();
	    display: block;
	    width: 100%;
	    padding-left: $week_w;

    }

    th, td {
	    position: relative;
    	display: block;
	    float: left;
		width: percentage(1/7);
		text-align: left;
        border-color: rgba(black, 0.2);
    }
    		
}

.admin-calendar__thead {
    position: absolute;
	top: 0;
	right: 0;
	bottom: auto;
	left: 0;

	display: block;
    width: 100%;
}

.admin-calendar__tbody {
    border-top: 1px solid rgba(black, 0.2);
    
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	display: block;
    width: 100%;
}

.admin-calendar__row {
    border-top: 1px solid rgba(black, 0.2);
    
    display: block;
    height: percentage(1/5);
    
    &:first-child {
        border-top: none;
    }
    
    .label--weekday {
        display: none;
    }
    
	&:first-child .label--weekday {
		display: inline;
	}
    

}

.admin-calendar__col {
    border-left: 1px solid rgba(black, 0.2);

	position: relative;
	
	display: block;
	height: 100%;
	
    color: $grey;
    
	.label--week {
		display: none;
	}

	&:first-child .label--week {
		display: block;
	}

}