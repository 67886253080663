@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$gap: 12px;
$col: 320px;
$row: $gap * 6;

.admin-widget--analytics-owners-vs-total {
    font-size: 14px;
    line-height: 24px;

    position: relative;

	// summary
	
	.summary {
    	position: absolute;
    	z-index: 100;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	margin: 24px;
    	pointer-events: none;
	}
	
	.summary__title {
    	font-size: 14px;
    	line-height: 24px;
    	max-width: 100%;
	}
	
	.summary__description {
        font-size: 12px;
        line-height: 20px;
        max-width: 40%;
	}

	.admin-widget__spinner {
    	margin-top: 1em;
	}

	.summary__value {
    	font-size: 36px;
    	line-height: 48px;
	}
	
    &[data-rows="1"] {

        .summary__description {
            display: none;
        }

    }

	&[data-rows="1"],
	&[data-rows="2"] {

    	.summary__title {
        	max-width: 50%;
    	}

    	.summary__value {
        	position: absolute;
        	top: 0;
        	right: 0;
        	margin-top: -12px;
    	}

    	.summary__description {
        	position: absolute;
        	top: 48px;
        	right: 0;
            text-align: right;
        	margin-top: -12px;
    	}
    
    	.admin-widget__spinner {
        	position: absolute;
        	top: 0;
        	right: 0;
            margin-top: 0;
    	}
    	
    	
    	
	}
	
	// legend
	
	.admin-legend {
    	position: absolute;
    	z-index: 100;
    	
    	top: auto;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	margin: 24px;
    	
    	ul {
        	margin: 0 -3px;
    	}
    	
    	li {
        	display: inline-block;
        	margin: 0 3px;
        	
        	&[data-count="2"] {
            	
            	&:first-child {
                	display: none;
            	}
            	
        	}
        	
    	}
    	    	
	}

	// chart
	
	.admin-chart {
    	position: absolute;
    	right: 0;
    	bottom: auto;
    	left: 0;
    	top: 50%;
    	
    	transform: translateY(-50%);
    	max-width: 180px + 36px;
    	max-height: 180px + 36px;

        padding: 18px;
    	margin: auto;
	}
	
	.admin-chart {
    	max-width: 120px + 36px;
    	max-height: 120px + 36px;
    }
    
    @include media(">=tablet") {
        
        @include grid-item-cols(">=8") {

        	.admin-chart {
            	max-width: 180px + 36px;
            	max-height: 180px + 36px;
            }
            
        }
        
    }
	
	// rows

	&[data-rows="1"] {

        .admin-legend,
    	.admin-chart {
        	display: none;
    	}
    	
	}

	&[data-rows="2"],
	&[data-rows="3"],
	&[data-rows="4"] {

        .admin-legend {

            li {
                display: none;
            }

        	li:last-child {
            	display: block;
        	}

        }
    	
	}
	
	&[data-rows="3"],
	&[data-rows="4"] {

        @include media(">=tablet") {

            @include grid-item-cols(">=8") {
            	
            	.admin-legend {
                	top: 0;
                    padding-left: 35*12px;
                	column-count: 2;
    
                    li {
                        display: block;
                    }
                    
            	}
            	
        	}

        }
    	
	}
	
	&[data-rows="5"],
	&[data-rows="6"],
	&[data-rows="7"],
	&[data-rows="8"] {

        .admin-widget__header {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
            height: 240px;
        }
    	
        .admin-widget__footer {
            position: absolute;
            top: 240px - 48px;
            right: 0;
            bottom: 0;
            left: 0
        }

    	.admin-legend {
        	top: 0;
        	bottom: auto;
    	}

    	.admin-legend {
        	column-count: 2;
        	
        	li {
            	display: block;
        	}

        }

        @include media(">=tablet") {

            @include grid-item-cols(">=8") {
                	
                .admin-widget__footer {
                    top: 240px;
                }        	
    
            	.admin-legend {
                	column-count: 4;
                	width: 100%;
                	
                	li {
                    	display: block;
                	}
    
                }
            	
            }

        }
        
    	
    }
	
}