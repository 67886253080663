// button

.admin-button--menu {
	display: block;

	padding: 2px 0;
	
	text-decoration: none;
	color: inherit;
	
	// overflow

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	// icon

	.admin-button__icon {
		float: left;
		color: rgba(black, 0.5);

      	width: 24px;
      	height: 24px;
      
      	font-size: 24px;
      	line-height: 1;
	}

	// label
	
	.admin-button__label {
		padding-left: 12px;
	}
	
	// count
	
	.admin-button__count {
    	
    	&:before {
        	content: ' (';
    	}

    	&:after {
        	content: ')';
    	}
    	
	}
	
	
    color: #666;
	
	&[aria-selected="true"] {
        color: black;
	}
    
}