// 64px header button

.admin-button--user {
    display: block;
    
    font-size: 24px;
    line-height: 1;
    
    outline: none;
    
    margin: 14px 6px;
    
    // avatar
  
    .admin-module__avatar {
        width: 36px;
        height: 36px;
    }

}
