@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget__loading {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: auto;
    text-align: center;
    transform: translateY(-50%);

 	font-family: $font-sans;
    font-size: 14px;
 	line-height: 20px;

    .admin-widget__loading-title {
    }

    .admin-widget__spinner {
        text-align: center;
        margin-top: -1.5em;
    }
    
}