@import "../../resources/fonts/MaterialIcons.scss";
@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field__label {
  display: inline-block;
  font-family: $font-sans;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;

  margin-top: 6px;
  margin-bottom: 6px;

  user-select: none; 
  color: inherit;
  
  .label__toggle {
    text-decoration: none;

  	&[lang]:after {
    	display: inline;
    	content: '[' attr(lang) ']';
    	font-weight: normal;
  	}

  }
  
  .label__summary {
    font-weight: normal;
  }
	
	.label__toggle:before {
		@extend .material-icons;
    display: block;
    float: left;

		content: "\E5D3";
    font-size: 18px;
    line-height: 1;
    padding: 3px;
    margin-right: 4px;
	}

	&.label--toggle {
  	display: block;
    border-bottom: 1px solid #ccc;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 0;
    margin-bottom: 0;

  	&[aria-expanded="true"] {
    	border-bottom: none;
  	}

	}
	
	&[aria-expanded="true"] .label__toggle:before {
    transform: rotate(90deg);
	}
	
	/*
	
	&[lang]:after {
  	display: inline;
  	content: '[' attr(lang) ']';
  	font-weight: normal;
	}
	
	*/
	
}