@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$header: 248px;
$avatar: 72px;

// module

.admin-module--inbox[data-size="xl"] {
    position: relative;
    padding-top: 19px;
    padding-right: 24px;
    padding-bottom: 20px;
    padding-left: 0;
    
    .admin-module__attachments {
        margin-left: $header;
    }

    .admin-module__inbox {
        
        .admin-module__avatar {
            display: none;
        }

        .admin-module__header {
            float: left;
            width: $header;
            padding-left: 24px;
            padding-right: 12px;
        }

        .admin-module__body {
            position: absolute;
            padding-left: $header;
            padding-right: 150px;
            right: 0;
        }
        
        .admin-module__date {
            float: right;
            text-align: right;
        }
    

    }

}