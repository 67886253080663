@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$max-owners: 7;

.admin-widget--user {
    position: relative;
    @include pie-clearfix()
    
    .admin-widget__grid {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .admin-widget__header {
        position: relative;
        background-color: $grey;
        width: 100%;
        height: 120px;

        .admin-module__avatar {
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 0;
            transform: translateY(-50%);
            font-size: 32px;
            margin-left: 0.5em;
            
        }
        
        .admin-module__avatar--image {

            .initials {
                display: none;
            }
            
        }

        .admin-module__header {
            position: absolute;
            top: 50%;
            right: 0;
            bottom: auto;
            left: 0;
            transform: translateY(-50%);
            margin-left: 90px;
        }

        .admin-module__title {
            display: block;
            font-size: 18px;
            font-weight: normal;
        }
    
        .admin-module__description {
            font-size: 14px;
        }

    }
    
    // body
    
    .admin-widget__body {
        position: absolute;
        top: 120px;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
    }

    // results

    .admin-widget__results {
        @include pie-clearfix()
        overflow-y: scroll;

        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    
    .admin-widget__empty {
        margin: 1em
    }

    // search
    
    .admin-widget__search {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
        bottom: auto;

        margin: 11px;
        
        + .admin-widget__results {
            padding-top: 60px;
        }
        
    }

    // min height
    
//    min-height: 120px + 60px * $max-owners;
    
}