@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-module--attachment {
    @include pie-clearfix()
  
    + .admin-module {
        margin-top: 9px;
    }


}

.admin-module--attachment[data-size="small"] {
    position: relative;
    font-size: 14px;
    line-height: 20px;
  
    min-height: 36px;

	padding: 8px 12px;
	
    white-space: nowrap;
    max-width: 100%;
    
    .admin-module__content {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 100%;
    }

	.admin-module__media {
		display: block;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		
		z-index: 2;
		overflow: hidden;

    	.media__title,
    	.media__description {
    		display: none;
    	}

		width: 36px;
		height: 36px;
	}

	.admin-module__media {

		+ .admin-module__content {
    		padding-left: 36px - 12px + 4px;
		}

    }
	
	.admin-module__header {
    	display: none;
	}

	.admin-module__label {
    	margin-right: 6px;
	}
	
	.admin-module__metadata {
    	display: inline;
    	margin-right: 6px;
	}

}

.admin-module--attachment[data-size="large"] .admin-module__attachment {
    position: relative;
    min-height: 64px;

    // media
  
	.admin-module__media {
		position: absolute;
		z-index: 2;
		overflow: hidden;

		display: block;
		width: 64px;
		height: 64px;
		
		+ .admin-module__content {
      		padding-left: 64px + 6px;
		}

	}

	.media__title,
	.media__description {
		display: none;
	}

    // content

	.admin-module__content {
      	padding: 12px;
      	width: 100%;
	}

	.admin-module__label {
    	margin-right: 6px;
	}
	
	.admin-module__metadata {
    	display: inline;
    	margin-right: 6px;
	}
	
	.admin-module__label + .admin-module__metadata {
	}

    .admin-module__footer {
        display: none;
	}      	
    
    .admin-module__title,
    .admin-module__status {
        font-size: 16px;
        line-height: 20px;
    }

}