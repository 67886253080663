@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-dashboard {
    position: absolute;
    background-color: #fafafa;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
}