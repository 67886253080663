.admin-buttongroup--settings {
    position: relative;
    float: left;
    
    + .admin-buttongroup--settings {
        margin-left: 6px;
    }
    
    // button settings
    
    .admin-button--settings {
        float: left;
    }
    
}