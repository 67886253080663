@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field.field--switch {
    display: inline-block;
	position: relative;
    user-select: none; 
    cursor: pointer;

	font-family: $font-sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	color: currentColor;
	
	margin: 0;

	input {
    	position: absolute;
        visibility: hidden;
	}
	
    .switch__label {
        float: left;
        margin-left: 6px;
        margin-right: 6px;
    }

    .switch__slider {
        float: left;
        position: relative;
      
    	width: 32px;
    	height: 18px;
        border-radius: 9px;
        background-color: $grey;
    	
    }
  
    .switch__slider:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
    
        width: 12px;
        height: 12px;
        margin: 3px;

        border-radius: 100%;
        
        background-color: white;
    }
  
	
	input:checked + .switch__slider {
    	
    	background-color: $blue;
        	
      	&:before {
//            border-width: 5px;
            left: auto;
            right: 0;
            
            
      	}
    	
	}

}