// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module__link {
    text-decoration: none;
    color: inherit;

    &:hover {
        cursor: pointer;

        .admin-module__title {
          	text-decoration: underline;
        }

    }
    
}