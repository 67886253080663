@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// mixins

@import "./Module__Small";
@import "./Module__Inline";

// module

.admin-module[data-layout="grid"] {
//    @include module-size-small();
//    @include module-inline();
    
//    position: relative;
    
    // toggle
    
    .admin-module__grid {
        
    }
    
    .nav-toggle {
        position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
        
        + .admin-module__grid {
            padding-left: 36px;
        }
        
    }
    
}