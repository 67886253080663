@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// size

.admin-buttongroup--options[data-type="size"] {
	position: relative;
	
	display: block;
	
	width: 36px * 6;
	height: 36px * 6;

  padding: 0;	
	margin: 1px;
	
	.admin-button + .admin-button {
  	margin: 0;
	}

  .admin-button__icon {
      display: none;
  }

	.admin-button__label {
  	display: block;

		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		left: auto;
		
		font-size: 12px;
		line-height: 18px;
		font-weight: bold;

		padding: 4px;
	}
	
	.admin-button--option {
		position: absolute;
		top: 0;
		left: 0;
		
    display: block;
        
		background-color: rgba(black, 0.125);
		outline: 1px solid white;
		
		padding: 0;
		margin: 0;

		overflow: hidden;
		
		&:hover {
			background-color: rgba(black, 0.5);
			color: white;
		}
		
		&[aria-selected="true"] {
			background-color: $blue;
			color: white;

			&:hover {
				background-color: rgba(black, 0.5);
				color: white;
			}
    		
		}

		&[value="xlarge"],
		&[value="xl"] {
			@include box-format(1, 1);
			z-index: 1;
		}

		&[value="xlarge"],
		&[value="xl"] {
			@include box-format(1, 1);
			z-index: 1;
		}
		
		&[value="large"],
		&[value="l"] {
			@include box-format(1, 0.75);
			z-index: 2;
		}

		&[value="l-m"] {
//			border-bottom: 1px dotted rgba(white, 0.25);
			@include box-format(2, 1);
			z-index: 3;
		}

		&[value="m-l"] {
//			border-right: 1px dotted rgba(white, 0.25);
			@include box-format(1, 1.5, 1/2);
			z-index: 4;
		}

		&[value="medium"],
		&[value="m"] {
			@include box-format(1, 1, 1/2);
			z-index: 5;
		}

		&[value="s-m"] {
//			border-right: 1px dotted rgba(white, 0.25);
			@include box-format(1, 2, 1/4);
			z-index: 6;
		}

		&[value="m-s"] {
//			border-bottom: 1px dotted rgba(white, 0.25);
			@include box-format(2, 1, 1/2);
			z-index: 7;
		}

		&[value="small"],
		&[value="s"] {
			@include box-format(1, 1, 1/4);
			z-index: 8;
		}

		&[value="xsmall"],
		&[value="xs"] {
			@include box-format(1, 1, 1/8);
			z-index: 9;
			
			.admin-button__label {
    			padding: 0;
			}
			
		}

    }
        
}