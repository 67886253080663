@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";


.admin-widget__header {
    @include user-select(none);
    
    &[aria-expanded="true"] {
        
        .admin-widget__title {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        
    }
    
    .admin-widget__avatar,
    .admin-widget__icon {
        width: 48px;
        height: 48px;
        
        float: left;
        margin-right: 8px;
        margin-top: -4px;
        margin-bottom: -4px;
    }
    
}