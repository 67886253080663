@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker__pointer {
    width: 12px;
    height: 12px;
    border-radius: 6px;

    &.pointer--slider {
        transform: translate(-6px, -1px);
        background-color: rgb(248, 248, 248);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
    }

    &.pointer--circle {
        transform: translate(-6px, -6px);
        box-shadow: inset 0 0 0 1px #fff;
    }
    
}
