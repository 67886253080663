@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker--large {
    
    .admin-colorpicker__preview {
        float: left;
        width: percentage(1/2);
        height: 24px * 8;
    }
    
    .admin-colorpicker__input {

        .field--hex {
            font-size: 24px;
        }

    }

    .admin-colorpicker__saturation {
        float: left;
        width: percentage(1/2);
        height: 24px * 6;
    }

    .admin-colorpicker__hue {
        float: left;
        width: percentage(1/2);
        height: 24px * 2;
    }
    
}