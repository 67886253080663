@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget__spinner {
    width: auto;
    margin: 0 auto;

    > div {
        width: 1em;
        height: 1em;

        background-color: $grey;
        margin: 0.25em 0.125em;
        
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
    
    .anim-1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .anim-2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }


}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}