@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.nav-preview {
  position: absolute;
  z-index: 2000;

  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  
  font-family: $font-sans;
  font-size: 16px;
  margin: 1em;

  .admin-button {
    display: none;
  } 

  .admin-button[aria-selected="true"] {
    display: block;

    .admin-button__icon {
      background-color: $blue;
    }

  }

  .admin-button__label {
      display: none;
  }
  
  &:hover {

    background-color: rgba(black, 1);
    color: white;
    
    .admin-button {
      display: block;
    }
    
    .admin-button[aria-selected="true"] {
      background-color: $blue;
      
      .admin-button__icon {
        background-color: transparent;
      }
      
    }

    .admin-button__label {
      display: block;
    }
      
  }

}