.admin-editor__header {
	position: absolute;
	top: 0;
	right: 0;
	bottom: auto;
	left: 0;
	
	background-color: white;
	
	.nav-path {
		float: left;
		padding: 16px 24px;
	}

	.nav-locale {
    	float: left;
//    	display: inline-block;
		margin: 20px 0;
	}
	
	.nav-toggle {
    	display: inline-block;
    	margin: 8px 0;
	}

	// status
	
	.nav-status {
      	position: relative;
      	float: right;
      	margin: 14px;
	}

	// nav-action

    .nav-action {
      	position: relative;
        float: right;
      	margin: 14px;

        .dropdown--action {
          top: 100%;
          right: 0;
        }

    }

}