@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-events__list {



}

.admin-events__list-item {
    position: relative;
    
    font-size: 14px;
    line-height: 20px;
    
    .admin-module__color {
        position: absolute;
        left: 0;
        width: 12px;
        height: 12px;
        margin: 4px 0;
    }
    
    .admin-module__title {
        font-weight: normal;
        padding-left: 16px;
    }

    .admin-module__link {
        
        .admin-module__title {
            font-weight: bold;
        }

    }

}