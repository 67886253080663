// link

.admin-button--tertiary {
    border-color: #999;

    display: inline-block;
    padding: 3px 6px;

    font-family: "Akkurat", sans-serif;

    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
	
	.admin-button__icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        line-height: 1;
        margin: 3px 0;
        
        + .admin-button__label {
            margin-left: 6px;
        }
        
	}
	
	.admin-button__label {
        display: inline-block;
        vertical-align: middle;
      	font-weight: bold;
        padding: 3px 0;
	}
	
	&:hover .admin-button__label {
    	padding-bottom: 2px;
        border-bottom: 1px solid;
        border-color: inherit;
	}
	
    &[aria-selected="true"] .admin-button__label {
        padding-bottom: 2px;
        border-bottom: 1px solid;
    }
	
}