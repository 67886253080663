@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker__swatches {
    background-color: $light;
    position: relative;
    width: 100%;
    
    > span {
        display: block;
        float: left;

        width: percentage(1/6);
        height: 24px;
    }

    div {
        display: block;
    }

}
