// import mixins

@import "../../resources/sass/mixins/all";

// dropdown

.admin-dropdown {
	position: absolute;
	z-index: 9999;

	display: block;

	background-color: white;
	@include card-depth(2);
	
	&[aria-expanded="false"] {
    	display: none;
	}

}

// top, right, bottom, left

.admin-dropdown--top {
	top: 0;
}

.admin-dropdown--right {
	right: 0;
}

.admin-dropdown--bottom {
	bottom: 0;
}

.admin-dropdown--left {
	left: 0;
}

// below, before, above, after

.admin-dropdown--below {
	top: 100%;
}

.admin-dropdown--before {
	right: 100%;
}

.admin-dropdown--above {
	bottom: 100%;
}

.admin-dropdown--after {
	left: 100%;
}
