// primary

.admin-button--primary {
  font-size: 24px;

  display: inline-block;
  white-space: nowrap;
  
  + .admin-button {
  }
  
  padding: 5px;
  
  background-color: black;
  color: white;

	.admin-button__icon {
  	float: left;
    text-align: center;

    font-size: 18px;
    line-height: 1;
    width: 18px;
    height: 18px;
            
    padding: 3px 6px;
  }
	
	.admin-button__label {
  	float: left;
  	font-size: 14px;
  	font-weight: bold;
  	line-height: 24px;
    padding: 0 9px;
        
    + .admin-button__toggle {
      margin-left: -12px;
    }
        
	}
	
	.admin-button__toggle {
  	display: block;
  	float: right;

    font-size: 24px;
    line-height: 1;
    vertical-align: middle;

    padding: 3px 4px;
  	margin-top: -3px;
  	margin-bottom: -3px;
  	margin-right: -3px;

  	border-color: #666;
  	
  	float: right;
	}

	.admin-button {
  	border: none;
  	float: left;
  	padding-right: 5px;
  	
  	float: left;
  	
  	+ .admin-button__toggle {
    	border-left: 1px solid;
  	}
    	
  }
	
}