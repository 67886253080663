.admin-button--apps {
    position: relative;
    
    display: block;
    font-size: 24px;
    line-height: 1;
    outline: none;
    margin: 14px 6px;
    
    .admin-button__icon {
        display: block;
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin: 6px;    
    }

	&[aria-expanded="true"] {

      	&:after,
      	&:before {
      		position: absolute;
      		z-index: 3000;
      		bottom: -6px;
      		left: 50%;
      		border: solid transparent;
      		content: " ";
      		height: 0;
      		width: 0;
      		pointer-events: none;
      	}
      
      	&:after {
      		border-bottom-color: white;
      		border-width: 8px;
      		margin-left: -8px;
      	}
    	
	}

}
