.admin-preview.preview--iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  
    .preview__iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        width: 100%;
        height: 100%;
        
        padding: 0;
        margin: 0;
        border: none;
    }
  
}

