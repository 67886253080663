@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field__toolbar {
    @include pie-clearfix();
    
    border: 1px solid #ccc;
    padding: 6px 0;
    
    + .admin-field__editor {
        margin-top: -1px;
    }
    
    .admin-field__language {
        position: absolute;
        top: 0;
        right: 0;
        margin: 12px;
    }
    
    .admin-buttongroup {
        display: inline-block;
        padding: 3px 6px;
        
        + .admin-buttongroup {
            border-left: 1px solid #ccc;
        }
        
    }

    .admin-button {
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        margin: 3px 6px;
        
        &[aria-selected="true"] {
            color: $blue
        }
        
    }

    .admin-button[value="bold"] {
        font-weight: bold;
    }

    .admin-button[value="italic"] {
        font-style: italic;
    }

    .admin-button[value="delete"] {
        text-decoration: line-through
    }

    
}