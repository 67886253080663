@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field.field--checkbox {
    display: inline-block;
	position: relative;
    user-select: none; 
    cursor: pointer;

	font-family: $font-sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	color: currentColor;
	
	margin: 0;

	input {
        visibility: hidden;
        position: absolute;
	}

    .checkbox__icon {
        float: left;
        position: relative;
      
    	width: 18px;
    	height: 18px;
        border-radius: 1px;
        background-color: $grey;
    	

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
        
            width: 14px;
            height: 14px;
            margin: 2px;
    
            border-radius: 0;
            
            background-color: white;
        }

        &:after {
            font-family: "Material Icons";
            content: "check";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 16px;
            text-align: center;
            border-radius: 0;
            background-color: transparent;
            color: transparent;
        }
    
    }

	.checkbox__label {
      	float: left;
      	margin-left: 6px;
      	margin-right: 6px;
	}
	
	input:checked + .checkbox__icon {

        &:before {
            width: 12px;
            height: 12px;
            margin: 3px;
        }


        &:after {
            background-color: $blue;
            color: white;
        }
    	
	}

}