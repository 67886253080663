@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-editor__sidebar {
	position: absolute;
	z-index: 1;

  background-color: $light;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	width: 50%;
	margin-left: -100%;

  transition: .25s ease-out;
	
	&[aria-expanded="true"] {
  	margin-left: 0;
    
    + .admin-editor__content {
      margin-left: 50%;
      
      + .admin-editor__preview {
        margin-left: 100%;
      }
      
  	}

    + .admin-editor__preview {
      margin-left: 100%;

      + .admin-editor__content {
        margin-left: 50%;
    	}

    }
    	
  }

	@include card-depth(2);
	
}