// button app

.admin-button--app {
    display: block;
    border: 1px solid;
    border-color: transparent;
    border-radius: 1px;

    padding: 2px;

    width: 90px;
    text-align: center;
    
    &:hover {
        border-color: #ccc;
    }
	  
    .admin-button__icon {
        position: relative;
        display: block;

        width: 64px;
        height: 64px;
        
        font-size: 36px;
        line-height: 1;
        padding: 14px;
        
//        color: $grey;
        
        margin: auto;
        
        img,
        svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            
            display: block;
            width: 100%;
            height: 100%;

        }
        
    }

    .admin-button__label {
        display: block;
        width: 100%;

        font-size: 12px;
        line-height: 18px;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 2px 4px 6px 4px;

    }
	
}