@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--analytics-live {
    font-size: 14px;
    line-height: 24px;
    
    // dark or light theme

    &[data-theme="dark"] {

        .summary__title,
        .chart__title,
        .details__title {
            color: rgba(white, 0.75);
        }
        
        .list-item {
            color: white;
        	border-color: rgba(white, 0.25);
        }
        
    }

    
    &[data-theme="light"] {
        
        .summary__title,
        .chart__title,
        .details__title {
            color: rgba(black, 0.75);
        }
        
        .list-item {
            color: black;
        	border-color: rgba(black, 0.125);
        }
        
    }
    
	// summary
	
	.summary {
    	position: relative;
    	margin: 24px;
    	
    	/*
    	
    	position: absolute;
    	
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	
        @include grid-max-height(4);
        
        */
	}
	
	.summary__title {
    	font-size: 14px;
    	line-height: 24px;
    	max-width: 100%;
	}

	.admin-widget__spinner {
    	margin-top: 1em;
	}

	.summary__value {
    	font-size: 36px;
    	line-height: 48px;
	}

	&[data-rows="1"],
	&[data-rows="2"] {

    	.summary__title {
        	max-width: 75%;
    	}

    	.summary__value {
        	position: absolute;
        	top: 0;
        	right: 0;
        	margin-top: -12px;
    	}
    
    	.admin-widget__spinner {
        	position: absolute;
        	top: 0;
        	right: 0;
            margin-top: 0;
    	}
    	
	}
	
    // chart
    
	.admin-chart {
    	position: absolute;
    	top: auto;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	
        height: 64px;
//    	padding: 0 24px;
    	
    	margin-bottom: 24px;
	}

	// details
	
	.details {
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        margin: 0 24px;
    }

	// list

	.list__title {
        font-size: 12px;    	
    	line-height: 20px;
    	margin-bottom: 4px;
	}
    
    .list {
    	width: 100%;
    	font-size: 14px;
    	line-height: 20px;
	}
    	
    .list-item {
    	@include pie-clearfix();
    	border-top: 1px solid;
    	padding-top: 1px;
    	margin-bottom: 2px;
	}
	
	.list-item__label {
    	float: left;
    	width: percentage(2/3);
    	white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
	}

	.list-item__value {
    	float: right;
    	width: percentage(1/3);
    	text-align: right;
	}
	
	// sizes

	&[data-rows="1"] {

    	.admin-chart, 
        .details {
        	display: none;
    	}
    	
	}

	&[data-rows="2"] {

    	.chart__title {
        	display: none;
    	}
    	
	}

	
	&[data-rows="2"],
	&[data-rows="3"] {

    	.details {
        	display: none;
    	}
    	
	}

	&[data-rows="4"] {
    	
    	.list-item {
        	display: none;
        	
        	&:nth-child(1),
        	&:nth-child(2),
        	&:nth-child(3) {
            	display: block;
        	}
        	
    	}
    	
	}

	&[data-rows="4"],
	&[data-rows="5"],
	&[data-rows="6"],
	&[data-rows="7"],
	&[data-rows="8"] {

        .admin-widget__header {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
            height: 204px;
        }
        
        .admin-widget__footer {
            position: absolute;
            top: 204px;
            right: 0;
            bottom: 0;
            left: 0
        }
    	
    }


	&[data-rows="5"],
	&[data-rows="6"],
	&[data-rows="7"],
	&[data-rows="8"] {

        .admin-widget__header {
            height: 240px;
        }
        
        .admin-widget__footer {
            top: 240px;
        }
    	
    }

	
}