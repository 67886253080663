.admin-finder__action {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  width: 50%;

  overflow-y: scroll;
	overflow-x: hidden;

  &[aria-expanded="true"] {
    left: -50%;
  }

}
