@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// user profile

.admin-module--user-profile {
    @include pie-clearfix();
    
    .admin-module__content {
        @include pie-clearfix();
        position: relative;
        height: 96px;
    }
    
    .admin-module__avatar {
        position: absolute;
        top: 0;
        right: auto;
        left: 0;
        bottom: auto;
        
        margin: 12px;
        width: 72px;
        height: 72px;
    }
    
    .admin-module__avatar + .admin-module__body {
      	padding-left: 96px;
    }
    
    .admin-module__body {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: 0;
        transform: translateY(-50%);
        padding: 0 12px;
        
        height: 40px;
    }

    .admin-module__title {
		font-size: 16px;
		font-weight: bold;
		display: block;
    }
    
    // footer
    
    .admin-module__footer {
        @include pie-clearfix();
        position: relative;
        
        background-color: #eee;
        padding: 18px;
        
        p {
            font-size: 14px;
            line-height: 18px;
            margin: 0;
        }
        
        strong {
            font-weight: bold;
        }
        
        > .admin-button {
            position: absolute;
            top: 0;
            right: 0;
            margin: 18px;
        }
            
    }
    
}