@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// action button

.admin-button--action {
    @include pie-clearfix();
    
    display: block;
    width: 100%;

    white-space: nowrap;

    font-size: 24px;
    font-weight: normal;

    color: $grey-dark;
    
    &:hover {
        background-color: $grey-light;
    }

    .admin-button__icon {
    	float: left;
        text-align: center;

    	height: 1.5em;
        width: 2.5em;
        
        color: $grey;

        padding: 0.25em 0;

        + .admin-button__label {
            margin-left: -12px;
        }

    }

	.admin-button__label {
    	background-color: transparent;
    	float: left;
    	font-size: 14px;
    	line-height: 24px;
        padding: 6px 14px;
	}

    
}

.admin-buttongroup--action {
    @include pie-clearfix();
    
    display: block;
    width: 100%;
    
	min-width: 36px * 6;
    padding: 12px 0;
    
    text-align: left;
    
    .admin-button,
    .admin-buttongroup {
        display: block;
    }
    
    + .admin-buttongroup {
        border-top: 1px solid $grey-medium;
    }
    
    .action__title {
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
    	color: inherit;
        padding: 0 12px;
        margin-bottom: 6px;
    }
    
}