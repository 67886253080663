@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

@mixin module-size-small() {
    font-size: 14px;
    line-height: 20px;

    .admin-module__title,
    .admin-module__status {
        font-size: 16px;
        line-height: 20px;
    }
  
    + .admin-module {
        margin-top: 6px;
    }

    // media
  
	.admin-module__content {
    	padding: 14px 12px;
	}

    // media
  
	.admin-module__media,
	.admin-module__icon {
		display: block;
		position: absolute;
		top: 0;
		left: 0;

		z-index: 2;
		overflow: hidden;

		width: 48px;
		height: 48px;

    	.media__title,
    	.media__description {
    		display: none;
    	}
		
		+ .admin-module__content {
    		padding-left: 48px + 4px;
		}
		
	}

    // avatar

	.admin-module__avatar {
		position: absolute;
		top: 0;
		left: 0;

		z-index: 2;
		overflow: hidden;

    	width: 36px;
    	height: 36px;
    	margin: 6px;

		+ .admin-module__content {
    		padding-left: 48px + 4px;
		}

	}    
}