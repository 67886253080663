@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker {
    @include pie-clearfix();

    position: relative;
    width: 100%;

    background-color: white;
    
    .admin-colorpicker__preview,
    .admin-colorpicker__saturation,
    .admin-colorpicker__hue {
        position: relative;
    }
    
}