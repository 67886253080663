@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--analytics {

    // header

	.admin-widget__header {
    	position: absolute;
    	z-index: 9;
    	top: 0;
    	right: 0;
    	bottom: auto;
    	left: 0;

        font-size: 16px;
        font-weight: normal;
        line-height: 1.5em;
    	
    	padding: 0.75em;
	}

	.admin-widget__title {
    	font-size: 1em;
        font-weight: normal;
    	line-height: 1.5;
        
        a {
            text-decoration: none;
            color: inherit;
        }

        a:hover {
            text-decoration: underline;
        }
        
	}

	.admin-widget__description {
    	font-size: 0.75em;
        font-weight: normal;
        line-height: 1.5;
	}
	
	// body

    .admin-widget__body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // chart

	.admin-chart {
    	padding: 0;

    	.grid {
        	display: none;
    	}
	}
	
	.admin-chart--pie {
    	position: absolute;
    	top: 50%;
    	
    	transform: translateY(-50%);
    	max-height: 180px;
    	margin: auto;
	}
	
	// cols, typo

    .admin-widget__header {
        font-size: 16px;
    }

	@include grid-item-cols(">=3") {

        .admin-widget__header {
            font-size: 16px;
        }

	}

	@include grid-item-cols(">=6") {

        .admin-widget__header {
            font-size: 18px;
        }
    	
	}
	
	// rows
	
	&[data-rows="1"] {
    	@include grid-row(1);
    	
    	.admin-chart--pie {
        	max-height: 60px
        }
    	
	}

	&[data-rows="2"] {
    	@include grid-row(2);

    	.admin-chart--pie {
        	max-height: 120px
        }
    	
	}

	&[data-rows="3"] {
    	@include grid-row(3);
    	
	}


}