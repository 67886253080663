@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-editor__body {
	position: absolute;
	z-index: 3;

	top: 64px;
	right: 0;
	bottom: 0;
	left: 0;
	
	width: 100%;
	
	background-color: white;
	background-color: $light;
	
	@include card-depth(2);
	
}