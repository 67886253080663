@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-inbox-editor {
    border-top: 1px solid $light;
}

.admin-inbox-editor__header {
    padding-top: 24px;
    padding-right: 12px;
    padding-left: 72px;
}

.admin-inbox-editor__compose {
    padding-right: 12px;
    padding-left: 72px;
    
    .admin-module__footer {
        margin-top: 24px;
    }
    
}


.admin-inbox-editor__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
    
    .admin-module__label {
        vertical-align: middle;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
    
}

.admin-inbox-editor__description {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
}

.admin-inbox-editor__attachments {
    margin-top: 12px;
    margin-bottom: 12px;
}

.admin-inbox-editor__array {
    margin-top: 24px;
    margin-bottom: 12px;
}

