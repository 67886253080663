.admin-finder__content {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow-y: scroll;
	overflow-x: hidden;

  &[aria-expanded="false"] {
    right: 50%;
  }

}
