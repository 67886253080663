@import "../../resources/sass/mixins/all";

.admin__header {
	position: absolute;
	z-index: 2;

	width: 100%;
	height: 64px;

	background-color: white;

	top: 0;
	right: 0;
	bottom: auto;
	left: 0;
	
	@include card-depth(1);

	overflow: hidden;
    transition: .125s ease-out;
	
	&[aria-expanded="false"] {
    	margin-top: -64px;
	}
	
}

.header__section {
  padding: 20px;
	text-align: center;
	
	a {
    text-decoration: none;
    color: inherit;
	}
	
}

.header__title {
  display: inline-block;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	
	+ .header__subtitle {
    	
  	&:before {
      display: inline;
    	content: ": ";
  	}
    	
	}
	
}

.header__subtitle {
  display: inline-block;
	font-size: 18px;
	font-weight: normal;
	line-height: 24px;
}

// search

.header__search {
    position: absolute;
    z-index: 2;
    
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    
    height: 64px;
    
    margin-top: -64px;
  
    display: block;
    background-color: white;
    transition: .125s ease-out;
    
    &[aria-expanded="true"] {
      margin-top: 0;
    }
    
    .admin-button--header-search {
      position: absolute;
      top: 64px;
    }
  
    .admin-button--header-close {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
        
      .admin-button__icon {
          opacity: 0;
      }
        
    }
    
}

// action

.header__action {
  position: absolute;
  z-index: 2;
  
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  
  height: 64px;
  
  margin-top: 64px;

  display: block;
  background-color: white;
  transition: .125s ease-out;

  padding: 20px;
	text-align: center;
    
  &[aria-expanded="true"] {
    margin-top: 0;
    
    + .header__search {
      margin-top: -64px * 2;
    }
    
  }

  .admin-button--header-close {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    
    .admin-button__icon {
    }
      
  }
    
}