@mixin user-select($value) {


-webkit-touch-callout: $value; /* iOS Safari */
    -webkit-user-select: $value; /* Safari */
     -khtml-user-select: $value; /* Konqueror HTML */
       -moz-user-select: $value; /* Firefox */
        -ms-user-select: $value; /* Internet Explorer/Edge */
            user-select: $value; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  
}