// sidebar

$width: 200px;
$width: 64px * 3.5;

.admin__sidebar {
	background-color: #fafafa;

	position: absolute;
	z-index: 2;

	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: $width;
	margin-left: $width * -1;

    transition: .125s ease-out;
  
	+ .admin__content {
        transition: .125s ease-out;
    }
	
	&[aria-expanded="true"] {
      	margin-left: 0;

          + .admin__content {
        	left: $width;
    	  }

	}
	
	// nav-action

  > .nav-action {
  	position: relative;
  	z-index: 2;
  	margin: 24px;
  }

  .dropdown--action {
    z-index: 3;
    top: 100%;
    right: 0;
  }
    
  // nav-finder
  
  > .nav-menu {
  	position: absolute;
  	z-index: 1;
  	padding-top: 84px;
  	padding-right: 16px;
  	padding-left: 24px;
  	top: 0;
  	right: 0;
  	bottom: 0;
  	left: 0;
  	overflow-y: scroll;	
  }
	
}

