@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--analytics-countries {
//	min-height: 480px;  
	
	.admin-widget__header {
    	padding: 24px;
    	position: relative;
	}
	
    .admin-widget__body {
        padding: 24px;
    }	
    
    .admin-chart {
        width: 100%;
        height: 200px;
    }
    
    .admin-widget__title {
    	font-size: 18px;
    	font-weight: normal;
    	line-height: 24px;
    }
    
    // list
    
    .list {
        width: 100%;

        font-size: 14px;
        line-height: 24px;

        color: $grey;

    }

    .list-item {
        @include pie-clearfix();
    }
    
	.list-item__label {
        float: left;
        width: percentage(1/4);	
        text-align: right;
        padding: 0 6px;
	}

	.list-item__value {
        float: left;
        width: percentage(1/4);	
        text-align: left;
        padding: 0 6px;
        display: none;
	}
	
	// sparkline

	.sparkline {
    	position: relative;
        float: left;
        width: percentage(1/2);
        overflow: visible;
        white-space: nowrap;
	}

	.sparkline {
        float: left;
        width: percentage(1/2);
        background-color: rgba(white, 0.25);
    	height: 4px;
    	margin: 10px 0;
	}
	
    .sparkline__bar {
        position: relative;
    	float: left;
    	height: 4px;
    	
    	&:after {
        	content: attr(data-value);
        	position: absolute;
        	top: 0;
        	left: 100%;
        	margin-top: -10px;
            text-align: left;
            padding: 0 6px;
    	}
    	
	}
    		
}