// media

.admin-media__preview {

  .preview--upload,
  .preview--audio,
  .preview--video {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: 0;
    margin: auto;
    transform: translateY(-50%);
    text-align: center;
  }
    
  .media__title {
    font-weight: bold;
  }
  
  .media__descr {
  }
  
  .preview--image {

    img {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 90%;
      max-height: 90%;
    }
    
  }
  
  
}