// black

$black: #2d2a26;

// greys

$grey: #808080;
$grey-light: #f2f2f2;
$grey-medium: darken($grey-light, 10%);
$grey-darker: darken($grey, 10%);
$grey-dark: darken($grey, 25%);

// beige

$beige: #a7a298;

// red

$red: #d94453;
$red-dark: #af3653;
$red-light: #ee7f90;

// green

$green: #047435;

// blue

$blue: #0666b0;
$blue-dark: #004388;
$blue-light: #8da4d5;

// dark + light

$dark: mix($black, white, 90%);
$medium: $grey-medium;
$light: $grey-light;