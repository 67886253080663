// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// calendar table

.nav-calendar {
    @include pie-clearfix();
}

.nav-calendar__header {
    position: relative;
	display: block;

	font-size: 14px;
	line-height: 24px;
}

.nav-calendar__title {
	padding: 2px 0;
}

.nav-calendar__pages {
    position: absolute;
    top: 0;
    right: 0;
    
    .admin-button {
        display: inline-block;
        padding: 2px;
        
        .admin-button__label {
            display: none;
        }

    }

    .admin-button--next {
        
        &:before {
            content: ">"
        }
        
    }

    .admin-button--prev {

        &:after {
            content: "<"
        }
        
    }

}
