@import "../../../resources/sass/mixins/all";
@import "../../../resources/sass/settings/all";

.admin-preview__field.field--reference {
  
  .reference__title {
    font-size: 1.25em;
  }
  
  .reference__id {
    font-family: $font-mono;
    font-weight: normal;
    font-size: 0.75em;
  }
  
}