@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// button path

.admin-button--path {
    float: left;
    display: block;
    white-space: nowrap; 
  
    font-weight: bold;

	text-decoration: none;
	color: inherit;
	
	padding: 4px 6px;
	
    .admin-button__label {
        display: block;
        float: left;
        
        font-size: 18px;
        line-height: 24px;
        
        color: inherit;
        
        vertical-align: baseline;
    }
  
    .admin-button__icon {
        display: block;
        float: left;
        
        font-size: 24px;
        line-height: 24px;
        
        padding: 0 20px;
        
        + .admin-button__label {
            margin-left: -8px;
        }
    
    }
  
    // popup
    
    &[aria-haspopup="true"] {
        padding: 3px 5px;
        border: 1px solid;
        border-radius: 2px;
        border-color: transparent;
    
        &:hover {
            border-color: $grey;
        }
  
        &[aria-expanded="true"] {
            border-color: $grey;
        }
      
    }
  
    &[data-type="search"] {
        font-weight: normal;

        .admin-button__label {

            &:before {
                content: '«';
            }
  
            &:after {
                content: '»';        
            }
      
        }
    }
    
}