@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// action button

.admin-buttongroup--action {
  @include pie-clearfix();
  
  display: block;
  width: 100%;
    
	min-width: 36px * 6;
  padding: 12px 0;
  
  text-align: left;
  
  .admin-button,
  .admin-buttongroup {
    display: block;
  }
  
  + .admin-buttongroup {
    border-top: 1px solid $grey-medium;
  }
  
  .action__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: inherit;
    padding: 0 12px;
    margin-bottom: 6px;
  }
    
}