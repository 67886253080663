@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

.admin-finder__section {
    @include pie-clearfix();
    position: relative;
    
    &[aria-expanded="false"] + .admin-finder__section {
        border-top: 1px solid #ccc;
        
        /*
        
        margin-top: 0;

        > .admin-finder__header {
            margin-top: 12px;
        }
        
        */

    }
  
    + .admin-finder__section {
        margin-top: 12px;
    }
  
    .admin-finder__section {
        margin-left: 0;
        margin-right: 0;
    }
  
    > .admin-finder__header {
        display: block;
//        @include pie-clearfix();

        margin-top: 12px;
        margin-bottom: 12px;

        .admin-module__title,
        .admin-module__description {
            display: inline;
            font-size: 14px;
            line-height: 24px;
        }

        .admin-module__title + .admin-module__description {
            &:before {
                content: " – "
            }
        }

        .admin-button--toggle {
            float: left;
            font-size: 18px;
            padding: 3px;
        }

        // nav-action
        
        > .nav-action {
            position: absolute;
            top: 0;
            right: 0;
            
            margin-top: -6px;
            margin-right: -6px;
            
        }

    }

  
}

.admin-finder__section-header  {
    margin-bottom: 12px;
    
    
}