@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-editor__settings {
	position: absolute;
	z-index: 2;

	top: 64px;
	right: 0;
	bottom: 0;
	left: 0;
	
	width: 100%;
	
	background-color: white;
	
//	@include card-depth(2);
	
	+ .admin-editor__content {
        transition: .25s ease-out;
	}
	
	&[aria-expanded="true"] + .admin-editor__body {
    	top: 100%;
	}
	
}