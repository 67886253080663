.admin-module__progress {
  position: absolute;
  z-index: 3;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  
  .progress {
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: #333;
    opacity: 0.25;
    transition: .125s linear;
  }
  
  opacity: 1;
  transition: .125s ease-out;

  &[data-progress="100"] {
    opacity: 0;
  }
  

}