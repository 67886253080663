@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module {
    @include pie-clearfix();
    
    position: relative;
    text-decoration: none;
  
 	display: block;
 	
 	font-family: $font-sans;
    font-size: 14px;
 	line-height: 20px;
 	
 	background-color: white;
 	color: black;
 	
 	&[data-shadow="true"] {
        border: 1px solid rgba(black, 0.125);
        border-radius: 1px;
    
        box-shadow: 1px 1px 0px 0px rgba(black, 0.125);
        
        &[aria-expanded="true"],
        &[aria-selected="true"],
    	&:hover {
    		border-color: rgba(black, 0.5);
    	}  
     	
 	}
 	
 	// settings

    > .nav-settings {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin-right: 8px;
    }

}