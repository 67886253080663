// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// calendar table

.nav-calendar__table {
    display: table;

	table-layout: fixed;
	border-collapse: separate;
	
	font-size: 12px;
	line-height: 22px;

	width: 100%;

	a {
		text-decoration: none;
	}

	th, td {
	}
	
	th {
		text-align: center;
		padding-left: 4px;
		padding-right: 4px;
	}
	
	td {
    	vertical-align: top;
    	text-align: right;
	}
	
}