@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$min-width: 300px;
$max-width: 400px;

.admin-dashboard__section {
    position: relative;
    margin: 20px auto;
    
    max-width: $min-width;

    @include media(">=tablet") {
        max-width: $min-width * 2;
    }

    @include media(">=desktop") {
        max-width: $min-width * 3;
    }

    @include media(">=wide") {
        max-width: $max-width * 3;
    }

}
