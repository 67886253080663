.admin-module__icon {
    display: inline-block;
    vertical-align: middle;

    width: 18px;
    height: 18px;
    
    &.material-icons {
      font-size: 18px;
      line-height: 1;
    }
    
    > svg {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

}
