.admin-finder__body {
    padding: 16px 24px;

	position: absolute;
    z-index: 1;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;

    overflow-y: scroll;
    overflow-x: hidden;
  
    .admin-finder__header + & {
      	padding-top: 64px;
    
        .admin-finder__content,
        .admin-finder__action {
          top: 64px;
        }
    
    }
  
}  