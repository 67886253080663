.admin-button--preview {
  font-size: 16px;
  display: block;
  width: 100%;
    
  opacity: 0.5;

  &[aria-selected="true"] {
    opacity: 1;
  }    

  .admin-button__icon {
    float: left;
    border-radius: 100%;
    padding: 0.75em;
    margin: -0.125em;
  }

  .admin-button__label {
    font-size: 1em;
    line-height: 1.2;
    float: left;
    padding: 1em;
    margin-left: -1em;
  }
  
}