@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

@mixin module-size-medium() {
    font-size: 14px;
    line-height: 20px;

    + .admin-module {
        margin-top: 9px;
    }
    
    .admin-module__content {
        min-height: 64px;
    }

    .admin-module__media,
    .admin-module__icon {
		position: absolute;
		
		top: 0;
		left: 0;

		z-index: 2;
		overflow: hidden;

        width: 64px;
        height: 64px;

    	.media__title,
    	.media__description {
    		display: none;
    	}

		+ .admin-module__content {
      		padding-left: 64px + 6px;
		}

    }

    // avatar

	.admin-module__avatar {
		position: absolute;

		top: 0;
		left: 0;

		z-index: 2;
		overflow: hidden;

    	width: 48px;
    	height: 48px;
    	margin: 8px;

		+ .admin-module__content {
    		padding-left: 64px + 6px;
		}

	}

	// title + status

    .admin-module__title,
    .admin-module__status {
        font-size: 16px;
        line-height: 20px;
    }
    
    .admin-module__title {
        display: block;
    }

}     