@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker__input {
    @include pie-clearfix();
    width: 100%;
    
	font-family: $font-mono;
    font-size: 16px;

	font-weight: 400;
	line-height: 24px;
    
    .field--input {

        input {
            border-color: transparent;
            background-color: transparent;

            font-family: $font-mono;
            font-size: inherit;
            line-height: inherit;

            color: inherit;
            padding: 3px 0;
//            border-bottom: 1px solid;

        	&:hover {
            	border-color: transparent;
        		border-bottom-color: currentColor;
        	}

        }
        
    }

    .field--hex {
        width: 100%;
        max-width: (16px * 3 * 3) + (3px * 2);
        margin: 0 auto;

    	font-family: $font-mono;
        font-size: 16px;
        
        input {
            text-align: center;
        }
            
    }

    .field--rgb {
        text-align: center;

        font-size: 12px;
        line-height: 18px;
        
        input {
            padding-left: 16px;
            text-align: left;
        }
        
        .r, .g, .b {
            display: inline-block;
            width: 14px * 3;
            position: relative;
            margin: 3px;
            
            &:before {
                position: absolute;
                padding: 4px 0;
                left: 0;
                width: 100%;
                text-align: left;
            }
            
        }
        
        .r:before {
            content: "R:"
        }

        .g:before {
            content: "G:"
        }

        .b:before {
            content: "B:"
        }
        
    }
    
}

