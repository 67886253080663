@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-section {
  position: relative;
  @include pie-clearfix();
    
  &[aria-expanded="false"] {

    .admin-section__body,
    .admin-section__footer {
      display: none;
    }    

  }
    
  + .admin-section {
    border-top: 1px solid $grey;
  }
  
  .admin-layout {
      margin: 0 -6px;
      
      > * {
          padding: 0 6px;
      }
      
  }
  
}

.admin-section__header {
  position: relative;
  padding: 12px;
  
  font-family: $font-sans;
    
  .nav-toggle {
  	position: absolute;
  	top: 0;
  	left: 0;
  }

}

.admin-section__footer {
    margin: 12px;
}

.admin-section__nav {
    padding: 6px;
}

.admin-section__title {
  display: inline;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	
	.nav-toggle + & {
    	margin-left: 36px;
	}

	text-decoration: none;
	color: inherit;
	
}

.admin-section__description {
    display: inline;
//    margin-left: 0.5em;
    
    &:before {
        content: " – "
    }
    
}

.admin-section__body {
  padding-left: 12px;
  padding-right: 12px;
//  padding-bottom: 12px;
    margin-bottom: 12px;

  .admin-section {
    clear: both;
    margin-top: 12px;
    margin-right: -12px;
    margin-bottom: -12px;
    margin-left: -12px;
  }
  
}