@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// size

.admin-buttongroup--options[data-type="grid"] {
	position: relative;
	
	display: block;
	
	width: 36px * 6;
	height: 36px * 6;

  padding: 0;	
	margin: 1px;
	
	.admin-button + .admin-button {
  	margin: 0;
	}

  .admin-button__icon {
      display: none;
  }

	.admin-button__label {
  	display: block;

		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		left: auto;
		
		font-size: 12px;
		line-height: 18px;
		font-weight: bold;

		padding: 4px;
	}
	
	.admin-button--option {
		position: absolute;
		top: 0;
		left: 0;
		
    display: block;
        
		background-color: rgba(black, 0.125);
		outline: 1px solid white;
		
		padding: 0;
		margin: 0;

		overflow: hidden;
		
		&:hover {
			background-color: rgba(black, 0.5);
			color: white;
		}
		
		&[aria-selected="true"] {
			background-color: $blue;
			color: white;

			&:hover {
				background-color: rgba(black, 0.5);
				color: white;
			}
    		
		}

		&[value="4:4"] {
			@include box-format(1, 1);
			z-index: 1;
		}

		&[value="4:3"] {
			@include box-format(4, 3);
			z-index: 2;
		}

		&[value="4:2"] {
			@include box-format(4, 2);
			z-index: 3;
		}

		&[value="4:1"] {
			@include box-format(4, 1);
			z-index: 4;
		}

		&[value="3:4"] {
			@include box-format(3, 4, 3/4);
			z-index: 5;
		}

		&[value="3:3"] {
			@include box-format(3, 3, 3/4);
			z-index: 6;
		}

		&[value="3:2"] {
			@include box-format(3, 2, 3/4);
			z-index: 7;
		}

		&[value="3:1"] {
			@include box-format(3, 1, 3/4);
			z-index: 8;
		}

		&[value="2:4"] {
			@include box-format(2, 4, 1/2);
			z-index: 9;
		}

		&[value="2:3"] {
			@include box-format(2, 3, 1/2);
			z-index: 10;
		}

		&[value="2:2"] {
			@include box-format(2, 2, 1/2);
			z-index: 11;
		}

		&[value="2:1"] {
			@include box-format(2, 1, 1/2);
			z-index: 12;
		}

		&[value="1:4"] {
			@include box-format(1, 4, 1/4);
			z-index: 13;
		}

		&[value="1:3"] {
			@include box-format(1, 3, 1/4);
			z-index: 14;
		}

		&[value="1:2"] {
			@include box-format(1, 2, 1/4);
			z-index: 15;
		}

		&[value="1:1"] {
			@include box-format(1, 1, 1/4);
			z-index: 16;
		}

    }
        
}