@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// owners

.nav-apps__by-user {
    @include pie-clearfix();
	
	.admin-apps {
    	padding: 20px 25px
	}
    
} 