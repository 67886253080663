@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$gap: 12px;
$col: 320px;
$row: $gap * 6;

.admin-widget--analytics-reg-vs-pub {
    font-size: 14px;
    line-height: 24px;

    position: relative;

    // dark or light theme

    &[data-theme="dark"] {

        .summary__title,
        .chart__title,
        .details__title {
            color: rgba(white, 0.75);
        }
        
        .list-item {
            color: white;
        	border-color: rgba(white, 0.25);
        }

    	.sparkline {
            background-color: rgba(white, 0.25);
    	}

    	.admin-legend {
        	color: white
    	}
    	
    }

    
    &[data-theme="light"] {
        
        .summary__title,
        .chart__title,
        .details__title {
            color: rgba(black, 0.75);
        }
        
        .list-item {
            color: black;
        	border-color: rgba(black, 0.125);
        }

    	.sparkline {
            background-color: rgba(black, 0.125);
    	}

    	.admin-legend {
        	color: black
    	}
        
    }
	
	// summary
	
	.summary {
    	position: absolute;
    	z-index: 100;
    	top: 0;
    	right: 0;
    	bottom: auto;
    	left: 0;
    	margin: 24px;
    	pointer-events: none;
	}
	
	.summary__title {
    	font-size: 14px;
    	line-height: 24px;
    	max-width: 100%;
	}

	.admin-widget__spinner {
    	margin-top: 1em;
	}

	.summary__value {
    	font-size: 36px;
    	line-height: 48px;
	}

	&[data-rows="1"],
	&[data-rows="2"] {

    	.summary__title {
        	max-width: 75%;
    	}

    	.summary__value {
        	position: absolute;
        	top: 0;
        	right: 0;
        	margin-top: -12px;
    	}
    
    	.admin-widget__spinner {
        	position: absolute;
        	top: 0;
        	right: 0;
            margin-top: 0;
    	}
    	
	}
	
	// legend
	
	.admin-legend {
    	position: absolute;
    	z-index: 100;
    	
    	top: auto;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	margin: 24px;
	}

	// chart
	
	.admin-chart {
    	position: absolute;
    	right: 0;
    	bottom: auto;
    	left: 0;
    	top: 50%;
    	
    	transform: translateY(-50%);
    	max-width: 180px + 36px;
    	max-height: 180px + 36px;

        padding: 18px;
    	margin: auto;
	}
	
	.admin-chart {
    	max-width: 120px + 36px;
    	max-height: 120px + 36px;
    }
    
    @include media(">=tablet") {
        
        @include grid-item-cols(">=8") {

        	.admin-chart {
            	max-width: 180px + 36px;
            	max-height: 180px + 36px;
            }
            
        }
        
    }

	// details
	
	.details {
    	position: absolute;
    	top: 0;
    	right: 0;
    	bottom: auto;
    	left: 0;
    }
	
	// list
    
    .list {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        color: $light;
    }

    .list-item {
        @include pie-clearfix();
    }
    
	.list-item__label {
        float: left;
        width: percentage(1/4);	
        text-align: right;
        padding: 0 6px;
	}

	.list-item__value {
        float: left;
        width: percentage(1/4);	
        text-align: left;
        padding: 0 6px;
	}

	.sparkline {
        float: left;
        width: percentage(1/2);
        background-color: rgba(black, 0.25);
    	height: 4px;
    	margin: 10px 0;
	}
	
    .sparkline__bar {
    	float: left;
    	height: 4px;
	}
	
	&[data-cols="1"],
	&[data-cols="2"],
	&[data-cols="3"],
	&[data-cols="4"] {
    	
    	.list-item__label,
    	.list-item__value {
        	width: percentage(1/3)
    	}

        .sparkline {
        	width: percentage(1/3)
    	}
    	
    }	

	// rows
	
	&[data-rows="1"] {

        .admin-legend,
    	.admin-chart,
    	.details {
        	display: none;
    	}
    	
	}

	&[data-rows="2"],
	&[data-rows="3"],
	&[data-rows="4"] {
    	
        .details {
        	display: none;
    	}
    	
	}

	&[data-rows="5"],
	&[data-rows="6"],
	&[data-rows="7"],
	&[data-rows="8"] {

        .admin-widget__header {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
            height: 240px;
        }
        
        .admin-widget__footer {
            position: absolute;
            top: 240px;
            right: 0;
            bottom: 0;
            left: 0
        }
    	
    }

	&[data-rows="6"],
	&[data-rows="7"],
	&[data-rows="8"] {

        .admin-widget__header {
            height: 240px + 72px;
        }
        
        .admin-widget__footer {
            top: 240px + 72px;
        }
    	
    }

	
}