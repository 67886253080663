@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.sidebar {
  background-color: $light;

	position: absolute;
	z-index: 1;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	.admin-button--header-close {
  	position: absolute;
  	z-index: 3;
  	top: 0;
  	right: 0;
  	bottom: auto;
  	left: auto;
	}
	
}
