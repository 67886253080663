@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-button--option {
  display: block;
  padding: 6px 3px;

  font-size: 14px;
  
  white-space: nowrap;

  .admin-button__skin,
  .admin-button__icon {
      float: left;
      display: block;
      font-size: 24px;
      width: 1em;
      height: 1em;
  }

  .admin-button__label {
      float: left;
      line-height: 24px;
      margin-left: 6px;
      margin-right: 6px;
      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
  }
  
  // selected
  
  &[aria-selected="true"] {
      color: $blue;
  }
        
}