.nav-locale {
  display: inline-block;
  position: relative;
  z-index: 9999;
  
  background-color: white;
  border: 1px solid;
  border-radius: 1px;

  .nav-locale__language {
    display: none;
  } 

  .nav-locale__language[aria-selected="true"] {
    display: block;
  }

  &:hover {

    .nav-locale__language {
      display: block;
    }
    
    .nav-locale__language[aria-selected="true"] {
      background-color: yellow;
    }
      
  }

}

.nav-locale__language {
  
  font-size: 12px;
  line-height: 18px;
  
  padding: 2px 5px;

  opacity: 0.5;

  &[aria-selected="true"] {
    opacity: 1;
  }    
  
}