@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-module__media {
	display: block;
	position: relative;
	z-index: 2;
	
	padding: 14px;
	
	> img {
        display: block;
        position: absolute;
        
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        width: auto;
        height: auto;
        
        max-width: 90%;
        max-height: 90%;
        
        margin: auto;
	}
	
    > svg {
        position: absolute;
        
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        width: auto;
        height: auto;
        
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
	
    // overflow
    
    .media__title,
    .media__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .media__title {
        font-weight: bold;
    }
	
}