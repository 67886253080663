@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.edit__focalpoint {
  display: block;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  /*
  width: 100%;
  height: 100%;
  margin: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  */
  
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
  
  .focalpoint__background {
    background-color: rgba(black, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  
  
  img {
      position: absolute;
      
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      margin: auto;
  }
  
  .focalpoint__reticle {
  	position: absolute;
  	z-index: 2;
  	
  	top: 50%;
  	right: auto;
  	bottom: auto;
  	left: 50%;
  	
  	transform: translate(-50%, -50%);
  }

  &:hover {
	cursor: crosshair;    	
  }

    
}

.focalpoint__reticle {
	display: block;
	width: 48px;
	height: 48px;

	pointer-events: none;

	background-color: rgba(white, 0.5);
	border-radius: 50%;

  opacity: 0.75;

	&:before {
		content: '';

		pointer-events: none;
		
		display: block;
		position: absolute;
		left: -15%;
		top: 50%;
		
		background-color: rgba(white, 1);
		
		width: 130%;
		height: 1px;
	}

	&:after {
		content: '';

		pointer-events: none;
		
		display: block;
		position: absolute;
		left: 50%;
		top: -15%;
		
		background-color: rgba(white, 1);
		
		width: 1px;
		height: 130%;
	}
			
}