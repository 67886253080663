@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.nav-sortable {
    @include user-select(none)
}

.admin-button--sortable {
	display: block;		
	padding: 12px;

    .admin-button__label {
      display: none;
    }

	.admin-button__icon {
      	display: block;
      	font-size: 24px;
        width: 1em;
        height: 1em;
        transition: .125s ease-in-out;
	}
	
	&[aria-expanded="true"] {

      	.admin-button__icon {
        	transform: rotate(90deg);
      	}

	}
    
}