@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-button--toolbar {
	display: block;

	width: 100%;
	height: 86px;

	background-color: transparent;
	border: 1px solid rgba(black, 0.25);

	font-size: 12px;
	font-weight: bold;
	line-height: 18px;
	
	text-align: center;
	
	padding: 12px 0;

	&:hover {
		border-color: black;
	}
	
	.admin-button__icon {
    	font-size: 24px;
		width: 24px;
		height: 24px;
		margin: 6px auto;
    	display: block;
	}
	
	.admin-button__label {
    	display: block;
	}
	
}