@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--apps {
    position: relative;
    text-align: center;
    overflow: hidden;
    
    .admin-apps {
        text-align: left
    }

    &[data-rows="1"],
    &[data-rows="2"] {
        
        .admin-widget__grid {
            max-width: 100%;
            overflow-x: scroll;
        }
        
    }
    
    
    &[data-rows="1"] {

        .admin-apps {
            padding: 9px;
            margin: 0 auto;
            white-space: nowrap;
        }        
        
        .admin-button--app {
            width: 54px;
            margin: 0 3px;

            .admin-button__icon {
                width: 48px;
                height: 48px;
            }
            
            .admin-button__label {
                display: none;
            }
            
        }
        
    }
    
    &[data-rows="2"] {

        .admin-apps {
            padding: 30px;
            margin: 0 auto;

            white-space: nowrap;
        }        
        
    }

    &[data-rows="3"],
    &[data-rows="4"],
    &[data-rows="5"],
    &[data-rows="6"],
    &[data-rows="7"],
    &[data-rows="8"] {
        
        .admin-widget__grid {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            
            margin: 0 auto;
            width: 100%;
            overflow-y: scroll;
        }

        .admin-apps {
            padding-top: 30px;
            padding-bottom: 30px;
        }        
        
        &[data-cols="4"] {
            .admin-apps {
                max-width: 3 * 90px;
            }
        }

        &[data-cols="8"] {
            .admin-apps {
                max-width: 6 * 90px;
            }
        }
        
    }
    
}

