@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-preview.preview--schema {
    background-color: white;
    border-color: $grey;
    color: $black;
    
    font-size: 1em;
    line-height: 1.5;  
    
    padding: 1em;
    
    .field__label {
        color: $blue;
    }

    &[data-type="media"] {
        background-color: #333;
        border-color: $grey;
        color: white;
        
        .field__label {
            color: $blue-light;
        }
    
    }

    .preview--iframe & {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
    }


}