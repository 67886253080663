// content

.admin-upload {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.admin-upload__dropzone {
  display: block;
  position: absolute;
  top: 18px;
  right: 18px;
  bottom: 18px;
  left: 18px;
  border: 1px dotted;
  border-color: #808080;

  .admin-buttongroup {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: 0;
      transform: translate(0, -50%);
      display: inline-block;
      text-align: center;
      margin: auto;        
  }

}

