@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

.admin-preview.preview--json {
    background-color: $black;
    color: $grey;
    
    .preview--iframe & {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
    }
    
    font-family: $font-mono;
    font-variant-ligatures: none;
    
    font-size: 14px;
    line-height: 1.25;
    
    .preview__json {
      
      padding: 0.5em;
    
      .__json-key__ {
        color: $grey
      }
      
      .__json-value__ {
        color: $blue-light
      }
      
      .__json-string__ {
        color: $light
      }
      
      .__json-boolean__ {
        color: $red-light
      }
        
    }
    
}

