.nav-status {
    position: relative;

	font-size: 14px;
	line-height: 18px;

    padding: 10px 0;
    
    .admin-button--edit {
        display: inline-block;
    }
    
    .status__date {
        display: inline-block;
        
        &:before {
            content: ' – ';
        }
    
    }
  
    .status__message {
        display: inline-block;
    
        &:before {
            content: ' – ';
        }

    }

}