// secondary

.admin-button--secondary {
  border: 1px solid;
  border-radius: 1px;
  padding: 6px 9px;
    
	font-size: 14px;
	line-height: 18px;

	> .admin-button__icon {
	}
    
  .admin-button__icon {
    font-size: 18px;
    line-height: 1;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    
    + .admin-button__label {
    }

  }
	
	.admin-button__label {
    display: inline-block;
    vertical-align: middle;
  	font-weight: bold;
	}
	
	.admin-button__toggle {
    font-size: 18px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;

    padding: 4px 5px;
  	margin-top: -4px;
  	margin-bottom: -4px;
  	margin-right: -9px;

  	float: right;
	}
	
	.admin-button {
  	float: left;
  	padding-right: 9px;
  	
  	+ .admin-button__toggle {
    	border-left: 1px solid;
  	}

	}
	
}