@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// mixins

@import "./Module__Small";
@import "./Module__Inline";

// module

.admin-module[data-layout="list"] {
    @include module-size-small();
    @include module-inline();
    
    position: relative;

    + .admin-module {
        margin-top: 6px;
    }
    
    // toggle
    
    .admin-module__grid {
        
    }

	.admin-module__header,
	.admin-module__footer {
    	display: inline;
	}

    .admin-module__status {
        display: inline;

        &:before {
          display: inline;
          content: ' – ';
        }

    }
    
    .nav-toggle {
        position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
        
        + .admin-module__grid {
            padding-left: 36px;
        }
        
    }
    
}