@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field__help {
	position: relative;
    font-family: $font-sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 0.25em;
	margin-bottom: 0.5em;
}