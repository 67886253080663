@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-buttongroup--auth-options {
    font-size: 14px;
    line-height: 24px;
    
    margin: -3px;
  
    > .admin-button {
        display: inline-block;
        margin: 3px;
    }
  
}

.admin-button--auth-option {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;		
    line-height: 1;

	padding: 0.25em 0.75em;

	padding: 2px 8px;

	border: 1px solid;
	border-radius: 1em;
	border-color: $grey;

    text-decoration: none;
    text-transform: capitalize;

	opacity: 1;

    &[aria-selected="true"] {
        background-color: $grey;
        color: white;
    }
    
}