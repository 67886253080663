@import "../../resources/sass/mixins/all";

.admin-legend {
	font-size: 12px;
	line-height: 18px;
	
	ul, li {
    	display: block;
	}
	
}

.admin-legend__chip {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 6px;
}

.admin-legend__label {
    display: inline;
    vertical-align: middle;
    
    + .admin-legend__label {
        &:before {
            content: " "
        }
    }
    
    + .admin-legend__value {
        &:before {
            content: ": "
        }
    }
    
}

.admin-legend__value {
    display: inline;
    vertical-align: middle;
    font-weight: bold;
}