@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-chart--nthDay {

    .tick--nthDay {

        .tick__month {
            transform: translateY(1.25em);
        }
        
    }
    
}

.admin-chart--7-days,
.admin-chart--14-days {

    .tick--nthDay {
        
        .tick__month {
            display: none;
        }

        &:first-child,
        &.tick--day-1 {
            
            .tick__month {
                display: block;
            }
            
        }
        
    }
    
}

.admin-chart--28-days {

    .tick--nthDay {
        display: none;
        
        &:nth-child(7n + 1) {
            display: block;
        }
        
    }
    
}

.admin-chart--90-days {

    .tick--nthDay {
        display: none;
        
        &.tick--day-1 {
            display: block;
        }
        
    }
    
}