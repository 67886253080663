@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

.admin-inbox__section {
  @include pie-clearfix();
  position: relative;
  
  &[aria-expanded="false"] + .admin-inbox__section {
    border-top: 1px solid #ccc;
    
    .section__header {
      margin-top: 9px;
    }
    
  }
  
  & + .admin-inbox__section {
    margin-top: 9px;
  }
  
  .admin-inbox__section {
    margin-left: 0;
    margin-right: 0;
  }
  
  .admin-finder__header {
    margin-bottom: 9px;
  }

  
}
