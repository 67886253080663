@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.field__description {
	position: relative;
    font-family: $font-sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 0.5em;
}