// calendar table

.admin-calendar__nav {
    
	.admin-button {
    	font-size: 14px;
    	line-height: 24px;
    	margin: 4px;
	}
	
	.admin-button--next {
    	display: inline-block;
    	
    	&:after {
        	content: " → "
        }
        
	}
	
	.admin-button--prev {
    	position: absolute;
    	left: 0;
    	
    	.admin-button__label {
        	display: none;
    	}

    	&:before {
        	content: " ← "
    	}
	}
	
	.admin-button + .admin-button {
    	margin-left: 12px;
	}
	
	
}