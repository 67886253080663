@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-editor__preview {
    position: absolute;
    z-index: 1;
    
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    
    margin-left: 50%;
    
    background-color: #333;
    color: white;

    transition: .25s ease-out;

    overflow: hidden;
    
    &[aria-expanded="true"]  + .admin-editor__content {
        width: 50%;
    }

    @include card-depth(2);
  
}