@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field__input {
	font-family: $font-sans;
    font-size: 16px;

	font-weight: 400;
	line-height: 24px;
	color: #222;

    
    .admin-field__language {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 12px;
        margin-top: -24px;
    }
	
}

.admin-field__input--text,
.admin-field__input--textarea {
    @include pie-clearfix();

	.nav-sortable + & {
    	
    	input,
    	textarea {
        	padding-left: 44px;
    	}
    	
	}
    
    input,
    textarea {
    	font-family: $font-sans;
        font-size: 16px;

        /*
    	font-family: $font-mono;
    	font-size: 14px;
        */

    	font-weight: 400;
    	line-height: 24px;

    	color: #444;
    	
        padding: 11px;
        margin: 0;
        
    	background-color: white;
        border: 1px solid #ccc;
    	outline: none;
    
    	&:not([size]),
    	&:not([cols]) {
    		width: 100%;
    	}
    	
    	&[disabled="disabled"] {
    		color: #ccc;
    	}
    
        &[readonly] {
          background-color: transparent;
          color: #999;
        }
        
        &[disabled] {
          background-color: transparent;
          color: #999;
        }
    	
    	&:hover {
    		border-color: rgba($blue, 1);
    	}
    	
    	
    	&[focus] {
        	background-color: white
    	}
    
    }
    
    textarea {
      min-height: 40px + 22px;
    }
    
    input[type="color"] {
    	-webkit-appearance: none;
        padding: 0;
    }
    
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    
    input[type="color"]::-webkit-color-swatch {
        border: none;
    }
    
}