@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.nav-settings {
  float: right;
  position: relative;
  
  @include user-select(none)
}

.nav-settings__item {
  float: left;
}