@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// buttongroup

.admin-buttongroup--filters {
    @include pie-clearfix();
    
	padding: 12px;
    position: relative;
	min-width: 36px * 5;
	
	.admin-button {
        @include pie-clearfix();
      	display: block;
      	width: 100%;
  	
      	+ .admin-button {
          	margin-top: 6px;
      	}
    	
	}
	
}