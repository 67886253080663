@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$gap: 12px;
$col: 320px;
$row: $gap * 6;

.admin-widget--owner {
//    min-height: 520px;

    .admin-widget__header {
        position: relative;
        z-index: 2;
        background-color: $grey;
        width: 100%;
        height: 240px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .admin-module__avatar {
            position: absolute;
            top: 0;
            right: auto;
            bottom: auto;
            left: 0;
//            transform: translateY(-50%);
            font-size: 32px;
            margin: 0.5em;
        }

    }
    
    .admin-widget__title {
        position: absolute;
        top: 100%;
        right: 0;
        bottom: auto;
        left: 0;
        
        padding: 12px;
        font-size: 18px;
        line-height: 24px;

        font-weight: normal;
        
        font-weight: normal;
        text-align: center;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;

    }

    .admin-widget__body {
        position: absolute;
        z-index: 1;
        top: 240px;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
        padding-top: 48px;
        padding-bottom: 24px;
    }
    
    .admin-apps {
        display: block;
        width: 90px * 3;
        margin: 0 auto;
    }

    
}

