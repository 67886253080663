@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$week_w: 24px;
$week_h: 48px * 3;

.admin-calendar__label.label--week {
	width: $week_w;

	position: absolute;
	
	top: 0;
	left: $week_w * -1;
	
	font-size: 12px;
	text-align: right;
	
	margin-top: 4px;
	padding-right: 4px;
}

.admin-calendar__label.label--weekday {
    display: block;
	font-size: 12px;
	margin: 4px 6px;
}


.admin-calendar__label.label--date {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100%;

    text-align: right;
	
	text-decoration: none;
	color: inherit;
	
	&[aria-selected="true"] {
        background-color: black;
        color: white;
        text-align: center;
        padding-right: 0;
	}
	
}

.admin-calendar__date {
	position: relative;
	
	display: block;
	height: 100%;
	
	padding-top: 24px;
	padding-left: 6px;
	
	color: black;
    opacity: 0.5;
    
    .label--date {
        position: absolute;
    
        top: 0;
        right: 0;

        padding-right: 4px;
    	margin: 4px 2px;
    }
    
    &.date--month {
        opacity: 1;
    }
	
}