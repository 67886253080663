// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// settings

$hour_w: 64px;
$hour_h: 48px;

// timetable

.admin-timetable {
	display: block;

	font-size: 14px;
	line-height: 24px;

    /*

	position: absolute;
	top: 64px;
	right: 0;
	left: 0;
	bottom: 0;
	
	*/

	@for $i from 1 through 7 {
	
		&[data-cols="#{$i}"] {
		
			th, td {
				width: percentage(1/$i);
			}
		
		}
	
	}

	thead {
		@include pie-clearfix();
		display: block;
	    width: 100%;

		th, td {
			padding-left: 0;
			padding-right: 0;
		}

	}
	
    tr {
		@include pie-clearfix();
	    display: block;
	    width: 100%;
	    padding-left: $hour_w;

		td .hour__label {
			display: none;
		}

		td:first-child .hour__label {
			display: block;
		}

    }

    th, td {
	    float: left;
	    position: relative;
	    display: block;
    }
    
	tbody {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		
		display: block;
	    width: 100%;

	    overflow-x: visible;
	    overflow-y: scroll;
	    
	    tr {
			border-top: none;
	    }

		th, td {
			padding: 0;
			border-left: 1px solid transparent;
		}
		

	}
		
}

// allday

.admin-timetable__allday {
	bottom: auto;
	margin-top: $hour_h / 2;

	th, td {
		border-color: transparent;
	}
	
}

// head

.admin-timetable__head {
    
    th, td {
        text-align: left;
    }
    
}

// body

.admin-timetable__body {
	margin-top: $hour_h;
	border-top: 1px solid rgba(black, 0.2);
	
	tr:first-child {
		margin-top: -1px;
	}
	
}


.admin-timetable__hour {
	display: block;
	position: relative;
	height: $hour_h;
	
	border-color: inherit;

	font-size: 12px;
	line-height: 14px;
	
	&:before {
		content: '';
		display: block;

		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;

		border-left: 1px solid rgba(black, 0.2);
		border-top: 1px solid rgba(black, 0.2);
	}

	&:after {
		content: '';
		display: block;

		position: absolute;
		left: 0;
		right: 0;
		top: $hour_h / 2;

		border-top: 1px dotted rgba(black, 0.2);
	}

	.hour__label {
		display: block;
		width: $hour_w;

		position: absolute;
		left: $hour_w * -1;
		
		padding: 4px;
		
		text-align: right;
	}

}

.timetable__events {
    position: relative;
    width: 100%;
    height: 100%;
}

.timetable__event {
    
}