// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// settings

.module--calendar {
    display: block;
    width: 100%;
    
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	
	&:before {
		display: inline-block;
		content: '\25CF';
		margin-right: 0.25em;
	}

	.module__datetime {
    	font-weight: bold;
	}

}