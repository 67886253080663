@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

.admin-finder__header {
    display: block;
//    @include pie-clearfix();
    @include user-select(none);

    position: relative;
	z-index: 2;
	
	&[aria-expanded="false"] {
    	&:hover {
        	cursor: pointer
    	}
	}

    .admin__content > .admin-finder > & {
        background: #fff; /* Old browsers */
        background: linear-gradient(to bottom, rgba(#fff, 1) 0%, rgba(#fff, 0.5) 75%, rgba(#fff, 0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
        
    .admin__action > .admin-finder > & {
        background: $light; /* Old browsers */
        background: linear-gradient(to bottom, rgba($light, 1) 0%, rgba($light, 0.5) 75%, rgba($light, 0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    
    > .nav-action {
        position: absolute;
        top: 0;
        right: 0;
    }

    .admin-finder > & {
        height: 64px;
        padding: 16px 24px;
        
        > .nav-action {
            margin: 16px 18px;
        }
        
    }
    
}

