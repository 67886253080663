@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

@mixin module-inline() {

    white-space: nowrap;
    max-width: 100%;
    
	.admin-module__content {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 100%;
	}
	
    .admin-module__color,
    .admin-module__icon {
        float: left;
        margin-right: 4px;
    }
    
    .admin-module__icon {
        float: left;
        margin-right: 4px;
        font-size: 18px;
        color: grey;
        line-height: 1;
    }


    .admin-module__title,
    .admin-module__description,
    .admin-module__label,
    .admin-module__metadata,
    .admin-module__byline {
        display: inline;
        vertical-align: baseline;
        width: auto;
    }

    .admin-module__metadata {
        display: inline;
        
        &:before {
          display: inline;
          content: ' ';
        }
          
    }

        	
}