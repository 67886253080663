@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field__options {
    margin-top: 12px;
    margin-bottom: 12px;

    &.options--block {
        
        > .admin-field {
            display: block;
            margin-top: 12px;
            margin-bottom: 12px;
            
            + .admin-field {
                margin-top: 6px;
            }
            
        }

    }
    
    &.options--inline {

        .form-field {
            display: inline-block;
        }
        
    }

}