@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// mixin

@mixin toolbar-items($x) {
	
	$items: $x - 1;

	width: percentage(1/$x);

	&:nth-child(-n+#{$items}) {
		display: block;
	}

}

.admin-buttongroup--toolbar {
    position: relative;
    @include pie-clearfix();
    
	margin: -3px;
	
	.admin-button {
		float: left;
		margin: 3px;

        display: none;
        
        max-width: 120px;
    	
    	@include toolbar-items(3);
    			
    	@include media(">=tablet") {
    		@include toolbar-items(3);
    	}
    
    	@include media(">=960px") {
    		@include toolbar-items(4);
    	}
    
    	@include media(">=1152px") {
    		@include toolbar-items(5);
    	}
    
    	@include media(">=1344px") {
    		@include toolbar-items(6);
    	}
    
    	@include media(">=1536px") {
    		@include toolbar-items(7);
    	}
    
    	@include media(">=1728px") {
    		@include toolbar-items(8);
    	}
    
    	@include media(">=1920px") {
    		@include toolbar-items(9);
    	}
    
    	@include media(">=2304px") {
    		@include toolbar-items(10);
    	}    

	}

}