@import "../../resources/sass/settings/all";

// content

.admin__action {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  width: 50%;
  
  background-color: $light;
  
  &[aria-expanded="true"] {
    margin-left: -50%;
  }
    
}

.action__items {
    float: left;
    width: 50%;
}

.action__editor {
    float: right;
    width: 50%;
}