.admin-chart--line-compare {

    .line {
        stroke-dasharray: 1;
    }
        
    .line:first-child {
        stroke-dasharray: none;
    }
    
    .point {
        stroke-width: 0
    }
    
    .point--is-current {
        stroke-width: 4
    }
    
}
