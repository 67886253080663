.admin-module__byline {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    .admin-module__date {
        display: inline;
    }

    .admin-module__author {
        display: inline;
        font-size: smaller;
    }
    
}