@import "../../resources/sass/settings/all";
    
.admin-finder {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
