// tree

.nav-menu__tree {
  
  .nav-menu__tree {
    margin-left: 24px;
  }
  
}


.nav-menu__treeitem {
	display: block;
	width: 100%;
	
	position: relative;
	
	&[aria-expanded="false"] {

  	> .nav-menu__tree {
  		display: none;
  	}

  }
	
	&[aria-expanded="true"] {
    	
  	.link__label {
  		padding-left: 6px;
  	}

  	> .nav-menu__tree {
  		display: block;
  	}
    	
	}
	
}

// toggle

.nav-menu__toggle {
	
	position: absolute;
	z-index: 2;
	top: 0;
	left: -24px;

	display: block;
	
	margin: 2px 0;
	
	width: 24px;
	height: 24px;

	font-size: 24px;
	line-height: 24px;

	&[aria-expanded="false"] {
  	transform: rotate(-90deg);
	}

  &:hover {
    cursor: pointer;
  }

	&:before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

		content: '\E5C5';
		display: block;
		opacity: 0.5;
	}

}