.sidebar__results {
  position: absolute;
  z-index: 1;

  padding: 18px;  
  
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  overflow-y: scroll;
  
}

.sidebar__search + .sidebar__results {
  top: 64px;
}