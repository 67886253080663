@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field.field--select {
    @include pie-clearfix();

    clear: both;
    display: block;

	position: relative;
	margin: 0;
	
    select {
    	z-index: 1;
        -webkit-appearance: none !important;
    
    	width: 100%;
    	height: auto;
    	
    	overflow: hidden !important;
    	text-overflow: ellipsis;
    
        font-family: inherit;
    	font-variant-ligatures: no-common-ligatures;
    	background-color: #fff;
    
    	border-radius: 0;
    	outline: none;
    
    	font-family: $font-sans;
    
    	font-size: 16px;
    	font-weight: 400;
    	line-height: 24px;
    	color: #222;
    	
    	border: 1px solid #ccc;
    	padding: 8px 10px;
    	padding-right: 30px;
    
    	margin: 0;

        option {
            
        }
    
        option[value] {
        }

    }
    

	&:after {
    	pointer-events: none;
    	
    	position: absolute;
    	z-index: 2;

    	top: 0;
    	right: 0;
    	
    	display: block;
    	width: 30px;
    	padding: 8px 0;
    	
    	font-size: 16px;
    	text-align: center;

        content: "\25BE";
        line-height: 22px;
    	
	}
    
}