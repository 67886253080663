.admin-module__status {
    opacity: 0.5;
  
    .status__date {
    }
    
    .status__byline {
    font-size: smaller;
    }
	
}

.admin-module {

  &[data-status="draft"] {

  	.admin-module__title {
    	font-weight: normal;
  	}
  	
  	.admin-module__status {
      font-weight: normal;
  	}
      
  }

	&[data-status="publish"] {
    	
      	.admin-module__title {
          font-weight: bold;
      	}
      	
      	.admin-module__status {
          font-weight: bold;
      	}

	}

	&[data-status="delete"],
	&[data-status="trash"] {

      	.admin-module__content,
      	.admin-module__status {
        	opacity: 0.5;
      	}
    
      	.admin-module__title {
          font-weight: normal;
      	}
      	
      	.admin-module__title,
      	.admin-module__description {
        	text-decoration: line-through;
      	}

	}
  
}