@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.sidebar__search {
  position: absolute;
  z-index: 2;
  
  background-color: $light;
  color: inherit;
  
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  
  height: 64px;

  @include pie-clearfix();    
  @include card-depth(1);  
}