@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$min-width: 64px * 2;
$min-height: 64px * 3;
$min-media: 64px * 2;

$max-width: 64px * 3;
$max-height: 64px * 4;
$max-media: 64px * 3;

$width: $max-width;
$height: $max-height;
$media: $max-media;

// module

.admin-module--card {

  .admin-module__grid {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  float: left;
  width: $min-width;
  height: $min-height;
  
  &.module--medium {
    width: $max-width;
    height: $max-height;

  	.admin-module__media,
  	.admin-module__action {
  		height: $max-media;
  		
  		position: absolute;
  		top: 0;
  		right: 0;
  		bottom: auto;
  		left: 0;
  	}

    .admin-module__content {
      top: $max-media
    }

  }
  
	
	.admin-module__media,
	.admin-module__action {
		display: block;
		z-index: 2;
		overflow: hidden;
		width: 100%;
		height: $min-media;
		
		position: absolute;
		top: 0;
		right: 0;
		bottom: auto;
		left: 0;
	}

	.admin-module__content {
		display: block;
		position: absolute;
    top: $min-media;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
  	width: 100%;
  	
  	padding: 0 12px;
  	
	}

	.admin-module__title {
  	width: 100%;
	}
	
  .admin-module__status {
    display: block;
    position: absolute;
    z-index: 3;

    top: auto;
    right: 0;    
    bottom: $media;
    left: 0;

    padding: 6px 12px;

	}
	
  .status__label,
  .status__date {
    display: inline-block;
  }

  .status__label + .status__date {

    &:before {
      content: " – "
    }

  }

  .admin-module__action {
    position: absolute;
		z-index: 3;

    > .admin-button {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    .admin-buttongroup {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);

      .admin-button {
        display: flex;
      }
      
      .admin-button__icon {
        margin-right: 6px;
      }
      
    }
    
  }

	.admin-module__status {
  	display: none;
	}
	
	.admin-module__action {
  	display: none;
	}
	
	&:hover .admin-module__action {
  	display: block;
  	background-color: rgba(white, 0.75);
	}
	
}
