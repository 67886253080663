.admin__content {
	position: absolute;
	z-index: 1;

	top: 0;		
	right: 0;
	bottom: 0;
	left: 0;

    &[aria-expanded="false"] {
        right: 50%;
    }
	
}