@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-preview__field {
    margin-bottom: 1.5em;
    font-family: $font-sans;
    
    // header
    
    &.field--header {
      margin-bottom: 0.5em;
    }
    
    .field__title {
        font-size: 1.5em;
        font-weight: 700;        
    }

    // meta
    
    .field__metadata {
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding-top: 0.5em;
        margin-top: 0.5em;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
    }

  .meta__label {
    display: inline;
    font-weight: bold;
    
    &:after {
        content: ":"
    }
    
  }
    
    // label
  
  .field__label {
    margin-bottom: 0.5em;
  }

  .field__type {
    font-family: $font-mono;
    font-weight: normal;
    color: #999;
    
    &:before {
      content: "("
    }

    &:after {
      content: ")"
    }
    
  }

  // string

  .field__string {
    font-size: 1.25em;
    
    p {
    }
    
    p + p {
        margin-top: 1em;
    }
    
  }

  &.field--string {

      
  }
  
  // array
  
  &.field--array {

    > .admin-preview__field {
      margin-top: 0.5em; 
      border-top: 1px solid;    
      border-color: #666;
      padding-top: 0.5em; 
    }
    
    border-bottom: 1px solid;
    border-color: #666;
    margin-bottom: 0.5em;

  }
  
  // boolean
  
  &.field--boolean {

      .field__boolean {
        font-size: 1.25em;
      }
      
      .boolean__name,
      .boolean__value {
        display: inline;
      }
    
      .boolean__value {
        display: inline;
        border: 1px solid;
        border-radius: 0.75em;
        padding: 0 0.5em;
        margin-left: 0.5em;
    }

      
  }

  
}