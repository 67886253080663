.admin-button--toggle {
  font-size: 1.5em;
  line-height: 1;
  
	display: block;		
	padding: 0.5em;

  .admin-button__label {
      display: none;
  }

	.admin-button__icon {
  	display: block;
  	font-size: 1em;
    width: 1em;
    height: 1em;
    transition: .125s ease-in-out;
	}
	
	&[aria-expanded="true"] {

  	.admin-button__icon {
    	transform: rotate(90deg);
  	}

	}
    
}