.admin-widget__icon {
    position: relative;
    display: block;

    width: 48px;
    height: 48px;
    
    float: left;
    margin-right: 8px;
    
    padding: 12px;
    
    img,
    svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        display: block;
        width: 100%;
        height: 100%;

    }
    
}