// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// calendar table

.admin-calendar {
	display: block;
	width: 100%;
	
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

    .admin-calendar__body {
        position: absolute;
        top: 64px;
        right: 0;
        bottom: 0;
        left: 0;
    }
    		
}