.admin__nav {
    position: absolute;
    z-index: 10;
	top: 0;
	right: 0;
	padding: 0 14px;

    .nav-login,
    .nav-user,
    .nav-apps {
    	float: left;
    	margin-left: 6px;
    }
    
    &[aria-expanded="false"] {
        margin-top: -64px;
    }

}