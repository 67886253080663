@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-geocoder__map {
    position: relative;
    width: 100%;
    height: 100%;
}

.admin-geocoder__map-center {
	display: block;
	width: 48px;
	height: 48px;

    position: absolute;
    
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

	pointer-events: none;

	background-color: rgba(white, 0.5);
	border-radius: 50%;

    opacity: 0.75;

	&:before {
		content: '';

		pointer-events: none;
		
		display: block;
		position: absolute;
		left: -15%;
		top: 50%;
		
		background-color: rgba(black, 1);
		
		width: 130%;
		height: 1px;
	}

	&:after {
		content: '';

		pointer-events: none;
		
		display: block;
		position: absolute;
		left: 50%;
		top: -15%;
		
		background-color: rgba(black, 1);
		
		width: 1px;
		height: 130%;
	}
			
}