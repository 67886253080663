@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget__search {
    @include pie-clearfix();
    
    .nav-search {
        background-color: transparent;
        position: relative;
        
        input[type="search"] {
            background-color: white;

            font-size: 14px;
            line-height: 18px;

            padding: 9px 18px;
            padding-left: 48px;
            
            border-radius: 18px;

//            @include card-depth(1);
            border: 1px solid $grey;
            
        }
        
        .input__icon {
            font-size: 20px;
            line-height: 24px;
            margin: 8px 16px;
        }
        
    }

    
}

