.nav-filter {
    position: relative;
    display: inline-block;
}

.nav-filter--right {
    float: right;
}

.nav-filter--left {
    float: left;
}