@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-widget--inbox {
//    min-height: 540px;
    
    .admin-widget__header {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        height: 120px;

        border-bottom: 1px solid #ccc;

    }

	.admin-widget__title {
        font-size: 20px;
        font-weight: normal;
        line-height: 30px;
        margin: 20px;
	}

	.admin-widget__description {
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        
        margin: 20px;
        margin-bottom: 12px;
	}
	
	// body
	
	.admin-widget__body {
    	position: absolute;
        top: 120px;
        right: 0;
        bottom: 0;
        left: 0;
        
	}
	
    .admin-inbox__results {
        position: absolute;
        top: -1px;
        right: 0;
        bottom: 0;
        left: 0;
        
        overflow-y: scroll;
    }


    // button
    
	.admin-button--widget {
    	position: absolute;
    	z-index: 3;

    	top: 0;
    	right: 0;
    	bottom: auto;
    	left: auto;

    	margin: 16px;
	}
	

}