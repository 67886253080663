@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-media {
  position: relative;
  width: 100%;

  padding-bottom: 100%;

  &[data-format="auto"] {
    padding-bottom: 0;
    border: 1px solid $grey;
    
    .admin-media__header {
        position: relative;
        color: black;
    }
    
  }

  .nav-settings {
      position: absolute;
      top: 0;
      right: 0;
      margin: 6px;
  }
  
}

.admin-media__header {
    @include pie-clearfix();
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: auto;
    right: 0;

    color: white;
    
    .nav-tabs {
      float: left;
      margin: 8px;
    }

    .nav-action {
      float: right;
      margin: 8px;
    }

}

.admin-media__content {
    @include pie-clearfix();
    padding: 12px;
}

.admin-media__preview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $grey;
}

.admin-media__footer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  border: 1px solid #ccc;

    text-align: center;

    .admin-buttongroup {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: 0;
        margin: auto;
        transform: translateY(-50%);
    }
    
}



.admin-media__edit {
}

