@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$light: #fafafa;

.admin-analytics-table {
    table-layout: fixed;
    width: 100%;
    
    background-color: white;
    border: 1px solid;
    border-color: $grey;
    
    font-family: $font-sans;
    
    font-size: 14px;
    line-height: 20px;

    tr + tr {
        border-top: 1px solid $grey;
    }
    
    th + th,
    th + td,
    td + td {
        border-left: 1px solid #ccc;
    }

    th, td {
        padding: 9px;
    }
    
    th {
        text-align: left;
    }
    
    td {
        text-align: right;
    }
    
    thead {
        background-color: $light;
        background-color: #ccc;

        tr + tr {
            border-top: 1px solid #999;
        }

        th + th,
        th + td,
        td + td {
            border-left: 1px solid #999;
        }
        
        th[aria-selected="true"],
        td[aria-selected="true"] {
            background-color: white;
        }
        

    }
    
    tbody {
        border-top: 1px solid;
        border-color: grey;
        background-color: $light;
        
        td[aria-selected="true"] {
            background-color: white;
        }
        
    }
    
    tr {
        
        th {
        }

        td {
        }
        
    }
    
    .admin-module__title {
        display: block;
        font-size: 16px;
    }

    .admin-module__metadata {
        display: block;
        font-size: 12px;
    }

}

.admin-analytics-table__label {
    font-size: 12px;
    font-weight: bold;

        @include user-select(none);
    
    &:hover {
        background-color: white;
        cursor: pointer
    }
    
    &[aria-selected="true"] {
        position: relative;
        
        i {
            display: block;
        }
        
    }
    
    i {
        position: absolute;
        top: 0;
        right: 0;
        margin: 9px;
        
        display: none;

        &:before {
            display: block;
            content: "→";
        }
        
        &[data-sort="asc"],
        &[data-sort="ascending"] {
            transform: rotate(-90deg);
        }

        &[data-sort="desc"],
        &[data-sort="descending"] {
            transform: rotate(90deg);
        }
        
    }
    
    
}

.admin-analytics-table__total {
    
    td {
    }

    b {
        display: block;
        font-family: $font-mono;
        font-size: 18px;
        line-height: 24px;
    }

    i {
        display: block;
        font-size: 12px;
        opacity: 0.5;
    } 
    
}

.admin-analytics-table__row {

    i {
        display: block;
        font-size: 12px;
        opacity: 0.5;
    } 
    
    td {
        
        b {
            font-family: $font-mono;
            font-size: 16px;
        }

    }
    
    
}