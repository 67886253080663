@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field.field--editor {
    @include pie-clearfix();
}

.admin-field__editor {
    position: relative;
	font-family: $font-sans;
    font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #222;

    /*
	font-family: $font-mono;
	font-size: 14px;
    */


    padding: 8px 10px;
    margin: 0;
    
    background-color: white;
    border: 1px solid #ccc;
	outline: none;
	
	span[contenteditable="false"] {
    	vertical-align: inherit !important;
	}
	
	code {
    	font-family: $font-mono;
    	font-size: 14px;
	}
	
	p {
    	font-size: 1em;
    	line-height: inherit;
    	margin: 1em 0;
	}
	
	h1, h2, h3, h4, h5, h6 {
    	font-weight: bold;
    	margin: 1em 0;
	}
	
	h1 {
    	font-size: 2em;
	}

	h2 {
    	font-size: 1.5em;
	}

	h3 {
    	font-size: 1.25em;
	}

	h4 {
    	font-size: 1em;
	}
	
	blockquote {
    	font-style: italic;
    	margin: 1em;
	}
	
	strong {
    	font-weight: bold;
	}

    em {
        font-style: italic
    }
    
    del {
        text-decoration: line-through
    }
    
    ul {
        list-style: disc;
        padding: 0 1em;
        margin: 1em;
    }

    ol {
        list-style: decimal;
        padding: 0 1em;
        margin: 1em;
    }
    
    li {
        margin: auto;
    }

	&[disabled="disabled"] {
		color: #ccc;
	}

    &[readonly] {
      background-color: transparent;
      color: #999;
    }
    
    &[disabled] {
      background-color: transparent;
      color: #999;
    }

}
