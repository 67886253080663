@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--analytics-chart {
	
	.admin-widget__header {
    	padding: 24px;
    	position: relative;
	}

    .admin-widget__title {
    	font-size: 18px;
    	font-weight: normal;
    	line-height: 24px;
    }

    .admin-widget__description {
    	font-size: 14px;
    	font-weight: normal;
    	line-height: 24px;
    }
	
    .admin-widget__body {
        position: absolute;
        top: 90px;
        right: 0;
        bottom: 0;
        left: 0;
    }	

	// rows
	
	&[data-rows="1"] {
    	@include grid-row(1);
	}

	&[data-rows="2"] {
    	@include grid-row(2);
	}

	&[data-rows="3"] {
    	@include grid-row(3);
	}    

	&[data-rows="4"] {
    	@include grid-row(3);
	}    
	
}