.admin-dashboard__header {
    margin-top: 20px;
    margin-bottom: 20px;
}

.admin-dashboard__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
}

.admin-dashboard__label {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
}
