@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// user dropdown

.admin-dropdown--user {
	min-width: 360px;
	top: 56px;
	right: 12px;
}


