// menu

.nav-path {
  display: block;
  list-style: none;
    
	-ms-user-select: none; /* IE 10+ */
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;

	font-size: 18px;
	line-height: 24px;

	color: inherit;
	
	margin: 0 -6px;

}

.nav-path__item {
    float: left;
	position: relative;
	
	white-space: nowrap;
	
    // icon

	&:after {

	}

	// hide everything but first and last

	display: none;

    &:first-child,
    &:last-child {
        display: block;
    }
	
//    	@include media(">=desktop") {
		display: block;
//    	}
	
	// hide last path arrow

    &:last-child {

		&:after {
			display: none;
		}
        
  }
    
}

.nav-path__item + .nav-path__item:before {
	font-family: 'Material Icons';
	content: '\E5CC';
	
	display: block;
	
	float: left;
	font-size: 20px;
	margin: 6px -3px;

	width: 20px;
	height: 20px;			
	
	opacity: 0.5;
}

/*

.nav-path__item + .nav-path__item.nav-path__item--search:before {
	font-family: inherit;
	content: ':';

	padding: 4px 6px;
	
	float: left;
	font-size: 18px;
	line-height: 24px;
	
	margin: 0;
}

*/

.nav-path__item--search {
    .admin-button--path {
        font-weight: normal;

        .admin-button__label {

            &:before {
                content: '«';
            }
  
            &:after {
                content: '»';        
            }
      
        }

    }
}

.nav-path__item--filters {
    .admin-button--path {
        font-weight: normal;
    }
}