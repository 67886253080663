@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-button--edit {
  display: inline-block;
  line-height: 24px;
  padding: 2px;
 
  .admin-button__icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    line-height: 1;
    width: 1em;
    height: 1em;
  }

  .admin-button__label {
    display: inline-block;
    vertical-align: middle;

    font-size: 14px;
    line-height: 24px;
    margin-left: 4px;
    margin-right: 4px;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    
  &:hover {
      
    .admin-button__label {
      border-bottom: 1px solid;
      border-color: $grey;
      margin-bottom: -1px;
    }
      
  }
    
}