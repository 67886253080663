@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$avatar: 60px;

// module

.admin-module--inbox[data-size="small"] {
    position: relative;
    padding: 12px 12px 12px 60px;
    
    .admin-module__inbox {
        
        .admin-module__avatar {
            position: absolute;
            top: 0;
            left: 0;
            bottom: auto;
            right: auto;
            margin: 12px;
        }

        .admin-module__header {
            margin-top: 8px;
        }
        
        .admin-module__body {
            margin-top: 8px;

            .admin-module__subject {
            }
    
            .admin-module__message {
            }

        }
        

        .admin-module__date {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 20px;
            margin-right: 12px;
            text-align: right;
        }
    
    }


}