@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-chart__error {
    
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: auto;
    
    text-align: center;
    
    transform: translateY(-50%)
    
}

.admin-chart {
    width: 100%;
    height: 100%;
    
    .gridline {
        stroke-width: 1;
        stroke: grey;
        opacity: 0.25;
    }
    
    .crosshair {
        stroke-dasharray: 1;
        stroke: black;
    }
    
    .crosshair-x {
        stroke-width: 1
    }

    .crosshair-y {
        stroke-width: 1
    }
    
    // ticks
    
    .tick__line {
        stroke-width: 1;
        stroke: grey;
        opacity: 0.25
    }
    
    .tick {
        font-family: $font-sans;
        font-size: 12px;
    }
    
    .tick--x {
        
    }
    
    .tick--y {
    }
    
    // right
    
    .tick--right {
        text-anchor: end;
        
        .tick__line {
            transform: translateX(0.5em);
        }
        
        .tick__label {
//            transform: translate(60px, 0.25em);
            transform: translate(60px, -1.25em);
            dominant-baseline: hanging
        }
        
        &:first-child .tick__label {
//            transform: translate(60px, -1.25em);
        }
        
    }

    .tick--bottom {
        text-anchor: middle;
        
        .tick__label {
            dominant-baseline: hanging;
            transform: translateY(1em);
        }

        .tick__month {
            transform: translateY(1.25em);
        }
        
    }

    .tick--bottom {
        text-anchor: middle;
        
        .tick__label {
            dominant-baseline: hanging;
            transform: translateY(1em);
        }

    }
        
}