@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

$gap: 12px;

.admin-finder__grid {
    display: grid;
    grid-gap: $gap;

    grid-template-columns: percentage(1/1);
    width: 100%;

    > [data-cols] {
    	grid-column: auto / span 1;
    }
    
    @include media(">=tablet") {
        grid-template-columns: percentage(1/2) percentage(1/2);
        width: calc(100% - 12px);

        > [data-cols="2"],
        > [data-cols="3"] {
        	grid-column: auto / span 2;
        }
    

    }

    @include media(">=desktop") {
        grid-template-columns: percentage(1/3) percentage(1/3) percentage(1/3);
        width: calc(100% - 24px);
        
        > [data-cols="3"] {
        	grid-column: auto / span 3;
        }

    }

    // rows

    @for $i from 1 through 8 {
        
        > [data-rows="#{$i}"] {
            @include grid-row($i)
        }
        
    }

}