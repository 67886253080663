@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module__description {
    display: inline;
  	max-width: 100%;

    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

  	font-weight: normal;
  	line-height: inherit;
  	
    vertical-align: baseline;
  	opacity: 0.75;
  	
  	strong {
      	font-weight: bold
  	}
  	
}