@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-geocoder {
    @include pie-clearfix();

    position: relative;

    width: 100%;
    min-height: 600px;

    .admin-geocoder__map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    
    .admin-geocoder__search {
        position: absolute;
        top: 0;
        left: 0;
        
        width: percentage(1/2);
        max-width: 320px;
        padding: 10px;
    }


    .admin-geocoder__input {
        float: left;
        width: percentage(1/2);
    }
    
}