// 64px header button

.admin-button--login {
  display: block;

  font-size: 24px;
  line-height: 1;

  outline: none;
  
  margin: 14px 6px;
  
  border-color: #666;
    
  // circle
    
	.admin-button__icon {
  	display: block;
   	overflow: hidden;
    width: 36px;
    height: 36px;
    margin: 0;

   	border: 1px dotted;
   	border-radius: 100%;
    border-color: inherit;

   	margin: -1px;

  	font-size: 20px;
  	line-height: 34px;
  	text-align: center;
	}
    
}
