// card depth

@mixin card-depth($depth, $alpha: 1) {
 	
  @if $depth < 1 {
    box-shadow: none;

  } @else if $depth == 'minimal' {
    box-shadow: minimal-bottom-shadow($depth), minimal-top-shadow($depth);  

  } @else if $depth > 5 {

    @warn "Invalid $depth `#{$depth}` for mixin `card`.";

  } @else {

    box-shadow: bottom-shadow($depth), top-shadow($depth);  
  }
}

/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  
  
  $blur: nth(1.5 3 10 14 19, $depth) * 2px;
  $color: rgba(black, nth(.06 .08 .10 .125 .15, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.12 .12 .12 .10 .10, $depth));

  @return 0 $primary-offset $blur $color;
}




/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function minimal-top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  
  $blur: nth(1.5 3 10 14 19, $depth) * 1px;
  $color: rgba(black, nth(.06 .08 .10 .125 .15, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function minimal-bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 2px;
  $color: rgba(black, nth(.06 .06 .06 .05 .05, $depth));

  @return 0 $primary-offset $blur $color;
}