@mixin pie-clearfix() {
	
    *zoom: 1;
	
    &:after {
        content: "";
        display: table;
        clear: both;
    }
	
}