@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field {
    @include pie-clearfix();
    
    &.sortable-helper {
        @include card-depth(2);
        z-index: 3000;
    }
    
}

.admin-field__body {
    @include pie-clearfix();

    position: relative;

    > .nav-sortable {
        position: absolute;
        top: 0;
        left: 0;
    }
    
    > .nav-settings {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin-right: 8px;
    }
    
}

.admin-field__footer {
    margin-top: 8px;
}

// nested array field

.admin-field--array .admin-field__body {
    
    > .admin-field--string + .admin-field--string {
        margin-top: 6px;
    }
        
    > .admin-field--array {
        margin: 12px;
        border: 1px solid #ccc;
        padding: 12px;
    }
    
}

// boolean field + checkbox or switch

.admin-field--boolean {
    margin-top: 12px;
    margin-bottom: 12px;
    
    + .admin-field--boolean {
        margin-top: 6px;
    }
    
}


