@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-module--inbox {
	@include pie-clearfix();
	
	display: block;
	width: 100%;

	overflow: hidden;
	
    font-size: 14px;
    line-height: 20px;

    // border, padding, margin
  
    border-top: 1px solid $light;
    padding-top: 12px;
    padding-bottom: 12px;

    .admin-module__attachments {
        margin-top: 12px;
    }

}

.admin-module__inbox {
    
    // link
    
    .admin-module__link {
        display: block;
        @include pie-clearfix();
    }
    
    // authors
    
    .admin-module__icon {
        margin-right: 6px;
    }
    
    .admin-module__author[data-status="unread"] {
        font-weight: bold;
    }
    
    .admin-module__author + .admin-module__count {
        font-size: smaller;
        margin-left: 0.25em;
    }
    
    // body
    
    .admin-module__header,
    .admin-module__body {
    	display: block;
    	max-width: 100%;
    	width: 100%;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	white-space: nowrap;
    }
    
    .admin-module__body {
        
        .admin-module__icon {
            font-size: 24px;
            width: 24px;
            height: 24px;
            margin-top: -4px;
            margin-bottom: -2px;
        }
        
    }
    
    .admin-module__label,
    .admin-module__subject,
    .admin-module__message {
        display: inline;
        vertical-align: baseline;
        width: auto;
    }
    
    .admin-module__label {
        margin-right: 0.5em;
    }

    .admin-module__subject {
        font-weight: bold;
    }
    
    .admin-module__subject,
    .admin-module__message {
        font-size: 16px;
    }

    .admin-module__subject + .admin-module__message {

        &:before {
            display: inline;
            vertical-align: baseline;
            content: " – "
        }

    }
    
}
    