@import "../../resources/fonts/MaterialIcons.scss";
@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-fieldset__label {
  display: inline-block;
  font-family: "Akkurat", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;

  margin-top: 6px;
  margin-bottom: 6px;

  user-select: none; 
  color: inherit;
}