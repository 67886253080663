@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

.admin__body {
	position: absolute;
	z-index: 1;

	top: 64px;
	right: auto;
	bottom: 0;
	left: 0;
	
	width: 100%;
	
	background-color: #fff;
    transition: .125s ease-out;
	
	.admin__header[aria-expanded="false"] + & {
    	top: 0;
	}
	
}