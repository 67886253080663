@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker--small {

    .admin-colorpicker__preview {
        float: left;
        width: percentage(1/1);
        height: 24px * 4;
    }

    .admin-colorpicker__saturation {
        float: left;
        width: percentage(1/2);
        height: 24px * 2;
    }

    .admin-colorpicker__hue {
        float: left;
        width: percentage(1/2);
        height: 24px * 2;
    }

    
}