// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// target size: 20px

.admin-module__label {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;		
    line-height: 1;

	padding: 0.25em 0.75em;

	padding: 2px 8px;

	border: 1px solid;
	border-radius: 1em;
	border-color: $grey;

    text-decoration: none;
    text-transform: capitalize;

	opacity: 1;
}