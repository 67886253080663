// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// settings

$hour_w: 64px;
$hour_h: 48px;

.module--timetable {

	display: block;
//	position: absolute;
	z-index: 2;
	
	width: 100%;
	max-width: 95%;

	min-height: $hour_h / 2;

	background-color: white;

	border: 1px solid rgba(black, 0.25);
	border-radius: 1px;

//	@include box-shadow(1px 1px 0px 0px rgba(black, 0.125));

	overflow: hidden;

	font-size: 12px;
	line-height: 16px;

	a {
		display: block;

		color: inherit;
		text-decoration: none;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		padding-top: 3px;
		padding-bottom: 3px;
		padding-left: 3px;

	}
	
	time {
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	&.item--large {
		
		time {
			display: block;
		}
		
	}

}