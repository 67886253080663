// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module__metadata {
    display: inline;
  	max-width: 100%;

	border-color: $grey;

	font-size: 12px;
	font-weight: normal;
	line-height: 20px;

	margin: 0;

    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

    .meta {
    	display: inline;
    	vertical-align: baseline;
    	opacity: 0.75;
    }
    
    .meta + .meta {
    
    	&:before {
    		font-weight: normal;
    		content: ' – ';
    	}
    	
    }
	
}