@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// mixins

@import "./Module__Small";
@import "./Module__Medium";
@import "./Module__Inline";

// module

.admin-module[data-layout="table"] {
    position: relative;

    + .admin-module {
        margin-top: 6px;
    }
    
    &[data-size="auto"] {
        @include module-size-medium()
    }

    &[data-size="small"] {
        @include module-size-small()
        @include module-inline()
    }

    font-size: 14px;
    line-height: 20px;
    
    // header, body, footer

	.admin-module__content {
    	float: left;
      	padding: 12px;
      	width: 50%;
	}
	
	.admin-module__header {
    	@include pie-clearfix();
      	overflow: hidden;
      	text-overflow: ellipsis;
	}
	
    .admin-module__footer {
        position: absolute;
		z-index: 2;
        top: 50%;
        right: 0;
        left: 50%;
        bottom: auto;
        
        transform: translateY(-50%);
        
      	padding: 12px;
      	padding-right: 64px;
	} 
	
    // label + metadata
    
	.admin-module__label + .admin-module__metadata {
    	display: inline;
    	margin-left: 6px;
    	margin-right: 6px;
	}

}