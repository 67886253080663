@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-editor__content {
	position: absolute;
	z-index: 2;

  background-color: $light;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;

  transition: .25s ease-out;
	
	overflow-y: scroll;

	@include card-depth(2);

}