@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-inbox-message {
    display: block;
    margin-bottom: 12px;

    position: relative;
    @include user-select(none);

    & + .admin-inbox-message {
        border-top: 1px solid $light;
        padding-top: 12px;
    }
    
    .admin-inbox-message__reply {
        position: relative;
        margin-top: 12px;
    }

    strong {
        font-weight: bold
    }
    
    .admin-module__avatar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        margin: 6px 18px;
    }
    
    .admin-module__body {
        position: relative;
    }
    
    .admin-inbox-message__headers,
    .admin-module__header,
    .admin-module__body,
    .admin-module__footer {
        position: relative;
        padding-left: 72px;
        padding-right: 12px;
    }
    
    .admin-module__header {
        margin-bottom: 24px;
    }
    
    .admin-module__title {
        font-size: 18px;
        line-height: 24px;
    }
    
    .admin-module__footer {
        margin-top: 12px;
    }

    .admin-module__label {
        display: inline-block;
    }
    
    .nav-settings {
        position: absolute;
        top: 0;
        right: 0;
        margin: 8px;
    }
    
    // status
    
    &[data-status="unread"] {

        .inbox-message__name {
            font-weight: bold;
        }

    }
    
    // content

    .admin-module__body {
        font-size: 14px;
        line-height: 20px;
    }
    
    &[aria-expanded="true"] {

        .admin-module__body {
            font-size: 16px;
            line-height: 24px;
            
            p {
                margin: 1em 0;
            }

            blockquote {
                font-style: italic;
                margin: 1em;
            }
            
            img {
                display: inline;
                width: 1.25em;
                height: 1.25em;
                margin: -.1em .1em;
            }


        }
        
    }

}
