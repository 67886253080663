@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";
@include reset();

.admin {
	background-color: white;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
    font-family: $font-sans;
}