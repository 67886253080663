@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module__title {
    display: inline;
  	max-width: 100%;

  	font-weight: bold;
  	line-height: inherit;

  	vertical-align: baseline;

    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	
  	&[data-value="false"] {
      	opacity: 0.5;
//    	font-weight: normal;
  	}

    &:hover {
        cursor: pointer;

        .admin-module__title {
          	text-decoration: underline;
        }

    }

	
}