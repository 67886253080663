@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-geocoder__search {
    @include pie-clearfix();
    width: 100%;
    position: relative;
}

.admin-geocoder__results {
    background-color: white;
    border: 1px solid #ccc;
    margin-top: 12px;
}

.admin-geocoder__prediction {
    
    h2 {
        font-weight: bold;
    }
    
    p {
        font-size: 12px;
        font-weight: normal;
    }
    
}