@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-chart--nthWeek {

    .tick--nthWeek {
        
        .tick__start {
            text-anchor: start
        }

        .tick__end {
            transform: translateY(1.25em);
            text-anchor: start;
        }
        
    }
    
    .tick--nthWeek {
        display: none;
        
        &:nth-child(4n + 1) {
            display: block;
        }
        
    }
    
}