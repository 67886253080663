@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-module--finder {

    &[data-layout="grid"] {
        @include grid-col(2)
        @include grid-row(2)
    }


}