@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-button--widget {

    border: 1px solid;
    border-radius: 18px;
    
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    
    padding: 9px 18px;
    
}