.admin-chart--area {

    .point {
        display: none
    }
    
    .point--is-current {
        display: block
    }
        
}
