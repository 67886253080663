@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

//$grid-gap: 12px;
//$row: $grid-gap * 6;

.admin-dashboard__grid {
    position: relative;
    margin: 20px auto;

    display: grid;
    
    @include grid-gap();
    @include grid-width(4)

    > [data-cols] {
    	grid-column: auto / span 4;
    	width: 100%;
    }

    grid-template-columns: $grid-col $grid-col $grid-col $grid-col;
    
    // 4 cols

    @for $c from 1 through 4 {
        
        > [data-cols="#{$c}"] {
            @include grid-col($c)
        }
        
    }

    // rows

    @for $r from 1 through 8 {
        
        > [data-rows="#{$r}"] {
            @include grid-row($r)
        }
        
    }

    // 18 cols

    @include media(">=tablet") {
        grid-template-columns: $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col;
        @include grid-width(8);

        > [data-cols] {
        	grid-column: auto / span 8;
        	width: 100%;
        }

        @for $i from 1 through 8 {
            
            > [data-cols="#{$i}"] {
                @include grid-col($i)
            }
            
        }

    }
    
    // 16 cols

    @include media(">=wide") {
        grid-template-columns: $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col $grid-col;
        @include grid-width(16);

        > [data-cols] {
        	grid-column: auto / span 16;
        	width: 100%;
        }

        @for $i from 1 through 16 {
            
            > [data-cols="#{$i}"] {
                @include grid-col($i)
            }
            
        }

    }

}
