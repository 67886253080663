@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-module--widget-owner {
    @include pie-clearfix();
    
    padding: 12px 16px;
    
    border-top: 1px solid $light;
    padding-top: 11px;
    
    .admin-module__avatar {
        
    }
    
    .admin-module__title {
        display: block;
        padding-left: 60px;
        padding-right: 12px;

        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        
        transform: translateY(-50%);
    }
    
}