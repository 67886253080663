// grid vars

$grid-gap: 12px;
$grid-col: $grid-gap * 6;
$grid-row: $grid-gap * 6;

// grid mixins

@mixin grid-gap($gap: $grid-gap) {
    grid-gap: $gap;
}

@mixin grid-height($rows) {
    $gaps: $rows - 1;
	height: ($grid-row * $rows) + ($grid-gap * $gaps);
}

@mixin grid-max-height($rows) {
    $gaps: $rows - 1;
	max-height: ($grid-row * $rows) + ($grid-gap * $gaps);
}

@mixin grid-row($rows) {
	grid-row: auto / span $rows;
    $gaps: $rows - 1;
	height: ($grid-row * $rows) + ($grid-gap * $gaps);
//	min-height: ($grid-row * $rows) + ($grid-gap * $gaps);
}

@mixin grid-width($cols) {
    $gaps: $cols - 1;
	width: ($grid-col * $cols) + ($grid-gap * $gaps);
}

@mixin grid-col($cols) {
	grid-column: auto / span $cols;
    $gaps: $cols - 1;
	width: ($grid-col * $cols) + ($grid-gap * $gaps);
	
}