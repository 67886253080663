.admin-colorpicker__preview {
    position: relative;
    width: 100%;
    
    .admin-colorpicker__input {
        position: absolute;
        margin: auto;
        top: 50%;
        transform: translateY(-50%)
    }
    
}
