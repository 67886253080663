@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-module--auth {
    
    &[data-status="new"] {
    }

    .admin-buttongroup--auth-options {
        display: inline-block;
        
        + .admin-module__status {
            display: inline-block;
            &:before {
                content: " – "
            }
        }
        
    }

    .admin-module__email + .admin-module__metadata {
        &:before {
            content: " – "
        }
    }

    &[data-size="small"] {

        .admin-module__content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        .admin-module__title,
        .admin-module__email,
        .admin-module__metadata {
            display: inline;
            vertical-align: baseline;
        }
        
        .admin-module__label {
            margin: 0 3px;
        }
        
        .admin-module__byline,
        .admin-module__status {
            display: inline
        }

        .admin-module__status + .admin-module__byline {
            &:before {
                content: " – "
            }
        }
        
    }

    &[data-size="auto"] {
        
        .admin-module__status {
            font-size: inherit;
        }

        .admin-module__footer {
        }

        
    }
    
}

.admin-button--auth-option {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;		
    line-height: 1;

	padding: 0.25em 0.75em;

	padding: 2px 8px;

	border: 1px solid;
	border-radius: 1em;
	border-color: $grey;

    text-decoration: none;
    text-transform: capitalize;

	opacity: 1;

    &[aria-selected="true"] {
        background-color: $grey;
        color: white;
    }
    
}