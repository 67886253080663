@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-apps {
    @include pie-clearfix();
    display: inline-block;
	width: auto;
    margin: auto;
}

.admin-apps__item {
    display: inline-block;
    vertical-align: top;
}
