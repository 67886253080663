@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-chart--nthHour {

    .tick--nthHour {
        display: none;
        
        &:nth-child(4n + 1) {
            display: block;
        }
        
    }
    
}