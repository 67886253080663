// 64px header button

.admin-button--header {
    display: block;

    font-size: 24px;
    line-height: 1;

    outline: none;
    
    .admin-button__icon {
        display: block;
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin: 20px;
    }
    
    // square
    
    &.admin-button--square {
        margin: 14px 6px;
        
        .admin-button__icon {
            margin: 6px;
        }
        
    }

    // circle
    
    &.admin-button--circle {
        margin: 14px 6px;

    	.admin-button__icon {
        	display: block;
           	border-radius: 100%;
           	overflow: hidden;
            width: 36px;
            height: 36px;
            margin: 0;
    	}
        
    }
    
}
