@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-dropdown--apps {
	min-width: 320px;
	max-width: 320px;

	top: 56px;
	right: 12px;
	
}