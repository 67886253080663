@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-button--setting {
  margin: 6px 3px;
  
  &[disabled] {
    opacity: 0.25;
  }

  .admin-button__label {
    display: block;

    height: 20px;
    min-width: 20px;
    
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    text-transform: none;
    
    text-align: center;
    padding: 0;
    
    overflow: hidden;
    
    border: 1px solid;
    border-color: inherit;
    border-radius: 2px;
    
    padding: 0 3px;
    margin: -1px;
  }

  .admin-button__icon {
    display: block;
    font-size: 18px;
    padding: 1px;
    margin: -1px;
    
    + .admin-button__label {
      display: none;
    }
      
  }
    
	&[aria-expanded="true"] {

    	&:after,
    	&:before {
    		position: absolute;
    		z-index: 3000;
    		bottom: -6px;
    		left: 50%;
    		border: solid transparent;
    		content: " ";
    		height: 0;
    		width: 0;
    		pointer-events: none;
    	}
    
    	&:before {
    		border-bottom-color: white;
    		border-width: 9px;
    		margin-left: -9px;
    	}
    	
    	&:after {
    		border-bottom-color: white;
    		border-width: 8px;
    		margin-left: -8px;
    	}
    	
	}    

}