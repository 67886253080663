@import "../../resources/sass/mixins/all";

.admin-tooltip {
    background-color: white;
	color: black;

	@include card-depth(2);

	padding: 12px;
	font-size: 14px;
	line-height: 20px;

}

.admin-tooltip__title {
    font-size: 12px;
    
    & + .admin-tooltip__value {
        font-size: 18px;
        line-height: 24px;
    }
    
}

.admin-tooltip__chip {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 6px;
}

.admin-tooltip__label {
    
    + .admin-tooltip__label {
        &:before {
            content: " "
        }
    }
    
    + .admin-tooltip__value {
        &:before {
            content: ": "
        }
    }
    
}

.admin-tooltip__value {
    font-weight: bold;
}

.admin-tooltip__change {
	font-size: 12px;
	
	&[data-trending]:before {
        width: 18px;
        text-align: center;
        display: inline-block;
        content: "→"
	}
	
	&[data-trending="flat"]:before {
	}

	&[data-trending="down"]:before {
        transform: rotate(90deg);
	}

	&[data-trending="up"]:before {
        transform: rotate(-90deg);
	}
	
}