.admin-chart--area-stacked {

    .point {
        stroke: black
    }
    
    .point--is-current {
        display: block;
    }
        
}
