@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--analytics-report-metrics {
    @include grid-row(4)
	
    .admin-widget__title {
    	font-size: 18px;
    	font-weight: normal;
    	line-height: 24px;
    }
    
    // header

	.admin-widget__header {
    	width: 100%;
        height: 60px;
	}

	.admin-widget__tab {
    	padding: 20px 24px;
    	position: relative;
        border-top: 4px solid;
        border-color: transparent
	}

    .admin-widget__body {
        position: absolute;
        top: 90px;
        right: 0;
        bottom: 0;
        left: 0;
    }	

    // metrics
	
	.admin-metric__label {
    	font-size: 14px;
    	font-weight: bold;
    	line-height: 20px;
	}

	.admin-metric__value {
    	font-size: 24px;
    	line-height: 30px;
	}
	
	.admin-metric__change {
    	font-size: 14px;
    	line-height: 20px;
    	
    	&[data-trending]:before {
            width: 18px;
            text-align: center;
            display: inline-block;
            content: "→"
    	}
    	
    	&[data-trending="flat"]:before {
    	}
    	
    	&[data-trending="down"] {
            color: red;

            &:before {
                transform: rotate(45deg);
            }
            
    	}

    	&[data-trending="up"] {
            color: green;
            
            &:before {
                transform: rotate(-45deg);
            }
    	}
    	
	}
	
	.admin-widget__tab {
    	float: left;
	}

}