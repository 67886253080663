@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-block {
    @include pie-clearfix();
    position: relative;
    
    display: block;
    
    border: 1px solid black;
    
    background-color: white;
    color: $black;

    + .admin-block {
        margin-top: 8px;
    }
    
    &[aria-expanded="true"] {
        @include card-depth(1);
    }

}

// inside section

.admin-section {
    
    /*

    .admin-block[aria-expanded="true"] {
        padding-left: 16px;
        padding-right: 16px;
        margin-left: -16px;
        margin-right: -16px;
    }
    
    */

}


.admin-block__header {
  position: relative;
  padding: 12px;

  .nav-toggle {
    	position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: auto;
  }
  
  .nav-toggle + .nav-settings {
    position: absolute;
    top: 0;
    right: 48px;
    margin: 9px 0;    
      
    .dropdown {
    		left: auto;
    		right: -24px;
    }
    
  }

}

.admin-block__title {
	display: inline-block;
	vertical-align: baseline;
	
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	
	text-transform: uppercase;
	text-decoration: none;

	color: inherit;
}

.admin-block__description {
	display: inline-block;
	vertical-align: baseline;
	
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	
	&:before {
  	content: ": ";
	}
    
}

.admin-block__body {
  padding: 12px;
}