.admin-buttongroup--inline {
    font-size: 14px;
    line-height: 24px;
    
    margin: -3px;
  
    > .nav-action,
    > .admin-button {
        display: inline-block;
        margin: 3px;
    }
  
}