$half: percentage(1/2);
$third: percentage(1/3);
$quarter: percentage(1/4);
$fifth: percentage(1/5);

.admin-layout--flexgrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > * {
        width: 100%;
    }
    
  // 2 cols
  
  &[data-grid="2"] {

    > [data-cols="1"] {
      width: $half;
    }

  }

  // 3 cols
  
  &[data-grid="3"] {

    > [data-cols="1"] {
      width: $third;
    }
  
    > [data-cols="2"] {
      width: $third * 2;
    }

  }

  // 4 cols
  
  &[data-grid="4"] {

    > [data-cols="1"] {
      width: $quarter;
    }
  
    > [data-cols="2"] {
      width: $quarter * 2;
    }

    > [data-cols="3"] {
      width: $quarter * 3;
    }

  }

  // 5 cols
  
  &[data-grid="5"] {

    > [data-cols="1"] {
      width: $fifth;
    }
  
    > [data-cols="2"] {
      width: $fifth * 2;
    }

    > [data-cols="3"] {
      width: $fifth * 3;
    }

    > [data-cols="4"] {
      width: $fifth * 4;
    }

  }

    
}