.admin-chart--line {

    .point {
        display: none
    }
    
    .point--is-current {
        display: block
    }

    .tick--bottom {
        text-anchor: middle;
        
        &:first-child {
            text-anchor: start
        }
        
        &:last-child {
            text-anchor: end
        }
        
    }
        
}
