@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-colorpicker__hue {
    position: relative;
    
    > div {
        margin: auto;
        width: 80%;
        height: 10px;
        
        > .hue-horizontal {
            border-radius: 5px;
        }
        
    }

}