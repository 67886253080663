@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-widget--data {
	
    .admin-widget__body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

	.admin-chart {
    	padding: 0;

    	.grid {
        	display: none;
    	}
	}

    // header

	.admin-widget__header {
    	position: absolute;
    	top: 0;
    	right: 0;
    	bottom: auto;
    	left: 0;
    	
    	z-index: 9;
    	
    	padding: 12px 14px;
	}

	.admin-widget__title {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        
        a {
            text-decoration: none;
            color: inherit;
        }

        a:hover {
            text-decoration: underline;
        }
        
	}

	.admin-widget__description {
    	font-size: 12px;
        font-weight: normal;
        line-height: 1.5;
	}


	&[data-cols="1"] {

    	
    }
    
    &[data-rows="1"] {
    	min-height: 160px;  
    }

    &[data-rows="2"] {
    	min-height: 320px;  
    }

    &[data-rows="3"] {
    	min-height: 480px;  
    }
	
	&[data-rows="2"],
	&[data-rows="3"],
	&[data-rows="4"] {
    
    	.admin-widget__title {
            font-size: 18px;
    	}

    	.admin-widget__description {
            font-size: 14px;
    	}

	}


    

}