@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

// button

.admin-button {
    position: relative;
	display: inline-block;
	
	text-decoration: none;
	text-transform: none;
	
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;

	vertical-align: middle;

	background-color: transparent;
	border: none;
	border-color: inherit;
	color: inherit;
	
	padding: 0;
	margin: 0;

  &:hover {
		cursor: pointer;
	}
	
	outline: none;

  // disabled 
  
  &[disabled] {
      
      opacity: 0.5;
    
    &:hover {
      cursor: progress;
    };
    
  }
  
  // haspopup + expanded
	
  &[aria-haspopup][aria-expanded="true"] {

  	&:after,
  	&:before {
  		position: absolute;
  		z-index: 3000;
  		bottom: -6px;
  		left: 50%;
  		border: solid transparent;
  		content: " ";
  		height: 0;
  		width: 0;
  		pointer-events: none;
  	}
  
  	&:before {
//    		border-bottom-color: black;
//    		border-width: 9px;
//    		margin-left: -9px;
  	}
  	
  	&:after {
  		border-bottom-color: white;
  		border-width: 8px;
  		margin-left: -8px;
  	}
    	
  }

}

.admin-button__icon {
    display: block;
    
    background-color: inherit;
    border-color: inherit;
    
    img,
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }

}

.admin-button__toggle {
    border-left: 1px solid;
    border-color: inherit;
}

.admin-button__label + .admin-button__toggle {
    border-left: none;
}



