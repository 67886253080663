@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-inbox-message__headers {
    font-size: 14px;
    line-height: 24px;

    .inbox-message__from,
    .inbox-message__metadata {
        display: block;
        max-width: 100%;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .inbox-message__name {
        font-size: 16px;
    }
    
    .inbox-message__email {
        &:before {
            content: "<"
        }
    
        &:after {
            content: ">"
        }
    }
    
    .inbox-message__metadata {
    }

    .inbox-message__to:after {
        content: " – "
    }

}

