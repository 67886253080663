.admin-button--status {
    
  .admin-button__icon {
    float: left;
    font-size: 18px;
    margin-right: 6px;
  }

  .admin-button__label {
    float: left;
    font-weight: bold;
    line-height: 18px;
  }
    
}