@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// 64px header button

.admin-module__avatar {
    display: block;
    position: relative;

    font-size: 18px;
    width: 2em;
    height: 2em;

    margin: 0;

   	border-radius: 100%;
   	overflow: hidden;

  	background-color: black;
  	color: white;

    background-position: center center;    
    background-size: cover;
	
	.initials {
    	position: absolute;
    	top: 50%;
    	right: 0;
    	bottom: auto;
    	left: 0;
    	
    	transform: translateY(-50%);
    	
    	display: block;
    	width: 100%;
    	text-align: center;

      	font-size: 0.875em;
      	line-height: 1;

	}
	
	.abbr {
    	position: absolute;
    	top: 50%;
    	right: 0;
    	bottom: auto;
    	left: 0;
    	
    	transform: translateY(-50%);
    	
    	display: block;
    	width: 100%;
    	text-align: center;

      	font-size: 0.75em;
      	line-height: 1;
	}
    
}
