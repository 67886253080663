// mixins

@mixin box-format($w: auto, $h: auto, $x: 1/1) {
	
	@if ($w == 'fill') {
		
		position: absolute;
		
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: auto;
		height: auto;
		
		max-width: none;
		max-height: none;

		padding-bottom: 0;
		
	} @else if ($w == 'fullscreen') {

		max-width: 100% !important;
		max-width: none;
		width: 100%;
		height: 100vh;
		padding-bottom: 100vh;

	} @else {

		width: percentage($x);
		height: 0;
		padding-bottom: percentage($h/$w * $x);
		
	}
		
}

@mixin module__img($w, $h, $x: 1/1, $scale: 1, $float: none) {

	.module__img {
		@include img-format($w, $h, $x: $x, $scale: $scale, $float: $float);
	}
	
	@if ($float != 'none') {
		
		.module__img + .module__content {
			float: $float;
			width: percentage(1-$x);
		}
		
	} @else {

		.module__img + .module__content {
			float: none;
			width: percentage(1/1);
		}
		
	}


}