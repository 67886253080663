.admin-widget__description {

	&[data-trending]:before {
        width: 1.25em;
        text-align: center;
        display: inline-block;
        content: "→"
	}
	
	&[data-trending="down"]:before {
        transform: rotate(45deg);
	}

	&[data-trending="up"]:before {
        transform: rotate(-45deg);
	}
	
}