@import "../../resources/sass/settings/all";

// content

.finder--section {

  
}

.finder--upload {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.upload--dropzone {
  display: block;
  position: absolute !important;
  top: 18px;
  right: 18px;
  bottom: 18px;
  left: 18px;
  border: 1px dotted;
  border-color: #808080;
}

  .admin-buttongroup--dropzone {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: 0;
      transform: translate(0, -50%);
      display: inline-block;
      text-align: center;
      margin: auto;        
  }


.finder--action {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  
  background-color: $light;
  
  &[aria-expanded="true"] {
    margin-left: -50%;
  }

  .action__list {
    float: left;
    width: 50%;
    padding-left: 18px;
    padding-right: 9px;
  }
  
  .action__editor {
    float: right;
    width: 50%;
    padding-left: 9px;
    padding-right: 18px;
  }    
  
  .action__schema {
    border: 1px solid;
    padding: 12px;
  }
  
  .nav-action {
    float: right;
  }

}

