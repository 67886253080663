// mixins + settings

@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// calendar table

.schedule {
	display: block;

	position: absolute;
	top: 64px;
	right: 0;
	left: 0;
	bottom: 0;

    overflow-x: visible;
    overflow-y: scroll;

}

.schedule__row {
    @include pie-clearfix();

    border-top: 1px solid rgba(black, 0.2);
    padding: 18px;
}


.schedule__date {
    float: left;
    width: 152px;
    padding: 0 6px;    
}

.schedule__events {
    float: left;
    padding: 0 6px;    
}

.schedule__event {
	display: block;
	
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	
	+ .schedule__event {
    	margin-top: 0.5em;
	}
	
}
