@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-field--suggest {
    position: relative;

    .admin-field__input {
        position: relative;
        width: 100%;
    }
    
    .admin-field__input--open {
        
    }
    
    .admin-field__suggest {
        display: none;
    }

    .admin-field__suggest--open {
        display: block;
        /*

        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        bottom: auto;
        
        width: 100%;
        */
        margin-top: -1px;
        
    }

}

.admin-field__suggest {
    background-color: white;
    border: 1px solid #ccc;
	outline: none;
}

.admin-field__suggest-list {
	font-family: $font-sans;
    font-size: 16px;

	font-weight: 400;
	line-height: 24px;
	color: #222;
}

.admin-field__suggest-item {
    padding: 8px 10px;
    margin: 0;
    
    + .admin-field__suggest-item {
        border-top: 1px solid #ccc
    }
    
}

.admin-field__suggest-item:hover,
.admin-field__suggest-item--highlight {
    background-color: $blue-light
}